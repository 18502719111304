import React from 'react';
import { Range } from 'react-range';

interface EscaleDurationProps {
  minDuration: number; // Durata minimă (1 oră)
  maxDuration: number; // Durata maximă (24 ore)
  values: number[]; // Valorile curente (două ore alese)
  onChange: (values: number[]) => void; // Funcția de schimbare a valorii slider-ului
  onReset?: () => void; // Funcția de resetare a slider-ului
}

const EscaleDuration: React.FC<EscaleDurationProps> = ({
  minDuration,
  maxDuration,
  values,
  onChange,
  onReset
}) => {
  const [initialValues] = React.useState<number[]>(values);  // Salvăm valorile inițiale
  const isModified = values[0] !== initialValues[0] || values[1] !== initialValues[1];  // Verificăm dacă s-au făcut modificări

  // Calculăm proporțiile corecte pentru a aplica gradienții
  const leftPercentage = ((values[0] - minDuration) / (maxDuration - minDuration)) * 100;
  const rightPercentage = ((values[1] - minDuration) / (maxDuration - minDuration)) * 100;

  // Stilul de fundal corectat pentru a avea gri pe laturi și albastru între thumb-uri
  const backgroundStyle = {
    background: `linear-gradient(to right, #e5e7eb ${0}%, #e5e7eb ${leftPercentage}%, 
                #1e40af ${leftPercentage}%, #1e40af ${rightPercentage}%, 
                #e5e7eb ${rightPercentage}%, #e5e7eb 100%)`
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-l font-bold">Durata escalei</h4>
        {isModified && onReset && (
          <button 
            onClick={onReset}
            className="text-blue-700 font-medium text-sm hover:text-blue-800"
          >
            Resetează
          </button>
        )}
      </div>
      
      <div className="px-2">
        <Range
          step={1}
          min={minDuration}
          max={maxDuration}
          values={values}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="h-1 w-full rounded-full"
              style={backgroundStyle} // Aplicăm fundalul corectat
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="h-5 w-5 bg-white border-2 border-blue-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
        />
      </div>

      <div className="flex justify-between items-center text-sm">
        <span className="font-medium">{values[0]} ore</span>
        <span className="font-medium">{values[1]} ore</span>
      </div>
    </div>
  );
};

export default EscaleDuration;
