import SectionHero from "components/SearchBlock/SearchBlock";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import HomeSearchBlock from "components/SearchBlock/SearchBlock";
import SearchBlock from "components/SearchBlock/SearchBlock";
import SectionFAQ from "components/SectionFAQ/SectionFAQ";
import AirlineSlider from "components/AirlineSlider/AirlineSlider";
import CountryList from "components/CountryList/CountryList";
import SectionBlog from "components/SectionBlog/SectionBlog";
import { useTranslation } from "react-i18next";
import AirlineOffers from "components/AirlineOffers/AirlineOffers";


const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Chișinău - Barcelona",
    description: "oferte de la 59€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/8298825/pexels-photo-8298825.jpeg",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Chișinău - Frankfurt",
    description: "oferte de la 69€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2467210/pexels-photo-2467210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Chișinău - Veneția",
    description: "oferte de la 49€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/586687/pexels-photo-586687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Chișinău - Londra",
    description: "oferte de la 29€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/1427579/pexels-photo-1427579.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Chișinău - Milano",
    description: "oferte de la 39€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2097092/pexels-photo-2097092.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Chișinău - Lisabona",
    description: "oferte de la 109€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://www.cruiseget.com/ro/uploads/file_62583965021ae.jpg",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Chișinău - Istanbul",
    description: "oferte de la 49€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3999943/pexels-photo-3999943.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Chișinău - Hamburg",
    description: "oferte de la 79€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/907649/pexels-photo-907649.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Chișinău - Bologna",
    description: "oferte de la 39€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/11478831/pexels-photo-11478831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Chișinău - Paris",
    description: "oferte de la 49€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460740/pexels-photo-460740.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Chișinău - Atena",
    description: "oferte de la 69€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/16958931/pexels-photo-16958931.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

const DEMO_CATS_3: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Iași - Berlin",
    description: "oferte de la 59€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/1128419/pexels-photo-1128419.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Iași - Roma",
    description: "oferte de la 49€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/1701595/pexels-photo-1701595.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Iași - Verona",
    description: "oferte de la 29€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/21550411/pexels-photo-21550411.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Iași - Madrid",
    description: "oferte de la 69€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3290070/pexels-photo-3290070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Iași - Lisabona",
    description: "oferte de la 89€",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/14681625/pexels-photo-14681625.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

function PageHome() {
  const { t } = useTranslation();
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <SearchBlock className="pt-10 lg:pt-16 lg:pb-16" />

      <div className="container relative pt-10 md:pt-20 mb-24 lg:mb-28">
        {/* SECTION HERO */}


        {/* SECTION 1 */}
        <SectionSliderNewCategories
            categories={DEMO_CATS}
            heading={t("oferteIarna.title")}
            subHeading={t("oferteIarna.description")}
            uniqueClassName="PageHome_s1"
            
        />

        {/* SECTION2 */}
        <SectionOurFeatures/>


        <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            className={"mt-10 md:mt-28"}
            heading={t("oferteChisinau.title")}
            subHeading={t("oferteChisinau.description")}
            uniqueClassName="PageHome_s1"
        />

        <SectionSliderNewCategories
            categories={DEMO_CATS_3}
            className={"mt-10 md:mt-28"}
            heading={t("oferteIasi.title")}
            subHeading={t("oferteIasi.description")}
            uniqueClassName="PageHome_s1"
        />
        <SectionFAQ />

        <AirlineOffers />

        <div className="relative py-16 mt-28">
          <BackgroundSection/>
          <SectionClientSay uniqueClassName="PageHome_"/>
        </div>
        <SectionBlog />
        <CountryList />
        <AirlineSlider />
        <SectionSubscribe2 className={"py-16"}/>
      </div>
    </div>
  );
}

export default PageHome;
