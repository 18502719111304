import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const infoChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/all-countries",
    name: "Țări",
  },
  {
    id: ncNanoId(),
    href: "/all-cities",
    name: "Orașe",
  },
  {
    id: ncNanoId(),
    href: "/air-companies",
    name: "Companii Aeriene",
  },
];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "ACASĂ",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "CĂUTA BILETE",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "DESPRE NOI",
  },
  {
    id: ncNanoId(),
    href: "https://t.me/hiflytravel",
    name: "TURISM",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "CONTACTE",
  },
  {
    id: ncNanoId(),
    href: "/faq",
    name: "ÎNTREBĂRI ȘI RĂSPUNSURI",
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "INFORMAȚII",
    type: "dropdown",
    children: infoChildMenus,
  },
];
