import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type CheckoutStateType = {
    flyItem: any;
}

let initialState: CheckoutStateType = {
    flyItem: null
}

const checkoutSlice = createSlice({
    name: 'checkoutStoreModule',
    initialState,
    reducers: {
        setFlyItem(state, action: PayloadAction<any>) {
            state.flyItem = action.payload.flyItem;
        },

        clearCheckout(state) {
            Object.assign(state, initialState);
        },
    }
})

export const {
    setFlyItem,
    clearCheckout
} = checkoutSlice.actions

export default checkoutSlice.reducer
