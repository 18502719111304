import React, { FC } from "react";
import SearchForm from "components/SearchForm/SearchForm";
import searchBg from 'assets/images/search_bg.png';
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
    className?: string;
    isSearchPage?: boolean;
}

const SearchBlock: FC<SectionHeroProps> = ({ className = "", isSearchPage = false }) => {
    const { t } = useTranslation(); // Hook-ul pentru traducere

    return (
        <div
            className={`nc-SectionHero flex flex-col justify-center relative mb-20${
                isSearchPage ? "h-[550px] lg:h-[450px] pb-[60px] lg:pb-[100px]" : "h-[500px] lg:h-[700px]"
            } ${className}`}
            style={{
                backgroundImage: `url(${searchBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
            data-nc-id="SectionHero"
        >
            {/* Suprapunerea de culoare */}
            <div
                className="absolute top-0 left-0 right-0 bottom-0 bg-[#0063a7] opacity-25 z-0"
            />

            <div className="flex flex-col items-center justify-center text-center text-white z-10">
                <h2 className="mt-4 px-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
                    {t('hero.title')} 
                </h2>
                <p className="mt-4 text-xs sm:text-sm lg:text-lg text-gray-200">
                    {t('hero.description')} 
                </p>
            </div>

            <div className="flex justify-center px-8 z-10 mb-12 lg:mb-0 w-full mx-auto"
                style={isSearchPage ? { marginTop: 20, marginBottom: -80 } : { marginTop: 0 }}>
                <SearchForm />
            </div>
        </div>
    );
};

export default SearchBlock;
