import React, { Fragment, useState } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import ButtonSubmit from "../ButtonSubmit";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";

export interface FlightDateRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  selectsRange?: boolean;
  onChangeRange: (dates: [Date | null, Date | null]) => void;
  originLocation: any;
  defaultValue?: any;
  destinationLocation: any;
}

const FlightDateRangeInput: FC<FlightDateRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  selectsRange = true,
  onChangeRange,
  originLocation,
  defaultValue,
  destinationLocation
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>();

  const onChangeRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChangeRange(dates);
  };

  const buildSearchUrl = () => {
    if (!originLocation || !destinationLocation || !startDate) {
      return "";
    }
    const originCityName = originLocation?.cityName;
    const destinationCityName = destinationLocation?.cityName;
    const originAeroportId = originLocation?.aerportId;
    const destinationAeroportId = destinationLocation?.aerportId;
    const start_date = startDate?.toLocaleDateString("en-US") || "2023-05-01";
    const end_date = endDate?.toLocaleDateString("en-US") || "2023-05-16";
    
    return `/search?originLocation=${originAeroportId}&originCity=${originCityName}&destinationLocation=${destinationAeroportId}&destinationCity=${destinationCityName}&startDate=${start_date}&endDate=${end_date}`;
  }

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {selectsRange && endDate
              ? " - " +
                endDate?.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {selectsRange ? "Pick up - Drop off" : "Pick up date"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`FlightDateRangeInput relative flex ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              {renderInput()}

              {startDate && open && (
                <ClearDataButton
                  onClick={() => onChangeRangeDate([null, null])}
                />
              )}
            </Popover.Button>

            {hasButtonSubmit && (
              <div className="pr-2">
                <ButtonSubmit href={`${buildSearchUrl() || ''}`} />
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800" />
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 right-0 z-50 md:mt-28 mt-18">
              <div className="md:w-auto md:min-w-[750px] overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-4 sm:p-8">
                {selectsRange ? (
                  <DatePicker
                    selected={startDate}
                    onChange={onChangeRangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    calendarClassName="w-full"
                    selectsRange
                    monthsShown={window.innerWidth >= 640 ? 2 : 1}
                    showPopperArrow={false}
                    inline
                    minDate={new Date()}
                    renderCustomHeader={(p) => (
                      <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                ) : (
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    monthsShown={window.innerWidth >= 640 ? 2 : 1}
                    showPopperArrow={false}
                    inline
                    renderCustomHeader={(p) => (
                      <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                      <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                  />
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default FlightDateRangeInput;