import React, { FC, useState } from "react";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import { useClerk, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFlyItem } from "../../store/modules/checkout.reducer";
import { ChevronDown } from "lucide-react";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: any;
}

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSignedIn } = useUser();
  const { redirectToSignIn } = useClerk();

  const { price, itineraries, validatingAirlineCodes } = data;
  const totalPrice = price.total;
  const currency = price.currency;
  const airlineCode = validatingAirlineCodes[0];
  const segments = itineraries[0].segments;
  const departureSegment = segments[0];
  const arrivalSegment = segments[segments.length - 1];
  const departureTime = departureSegment.departure.at;
  const arrivalTime = arrivalSegment.arrival.at;
  const departureAirport = departureSegment.departure.iataCode;
  const arrivalAirport = arrivalSegment.arrival.iataCode;
  const duration = itineraries[0].duration;
  const numberOfStops = segments.length - 1;

  const formatDuration = (isoDuration: string) => {
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = isoDuration.match(regex);
    if (!matches) return "";
    const hours = matches[1] ? `${matches[1]}h` : "";
    const minutes = matches[2] ? `${matches[2]}m` : "";
    return `${hours} ${minutes}`.trim();
  };

  const formatTime = (dateTime: string) => {
    return new Date(dateTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleReserveClick = () => {
    dispatch(setFlyItem({ flyItem: data }));
    if (isSignedIn) {
      navigate("/checkout");
    } else {
      redirectToSignIn({
        redirectUrl: "/checkout",
      });
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-md transition-shadow p-4 mb-4">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-8">
          {/* Airline Logo */}
          <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
            <span className="text-xl font-bold">{airlineCode}</span>
          </div>

          {/* Flight Times and Route */}
          <div className="flex-1">
            <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
              <div className="text-lg font-semibold">
                {formatTime(departureTime)} - {formatTime(arrivalTime)}
              </div>
              <div className="text-gray-500 text-sm md:text-base">
                {departureAirport} - {arrivalAirport}
              </div>
            </div>
            <div className="text-sm text-gray-500 mt-1">
              {formatDuration(duration)}
              {numberOfStops > 0 && (
                <span className="ml-2">
                  • {numberOfStops} {numberOfStops === 1 ? "escală" : "escale"} {" "}
                  <span className="hidden md:inline">
                    {segments.map((s: any) => s.arrival.iataCode).join(", ")}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Price and Book Button */}
        <div className="flex items-center justify-between md:justify-end md:space-x-6">
          <div className="text-left md:text-right">
            <div className="text-xl md:text-2xl font-bold text-[#2163a7]">
              {currency} {totalPrice}
            </div>
            <div className="text-xs md:text-sm text-gray-500">Tarif pentru 1 pasager</div>
          </div>
          <div className="flex items-center space-x-2 md:space-x-6">
            <ButtonPrimary 
              onClick={handleReserveClick}
              className="bg-[#2163a7] hover:bg-indigo-700 text-white px-4 md:px-8 py-2 md:py-3 rounded-lg text-sm md:text-base"
            >
              Continuă
            </ButtonPrimary>
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronDown 
                className={`w-5 h-5 transition-transform ${isOpen ? "transform rotate-180" : ""}`}
              />
            </button>
          </div>
        </div>
      </div>

      {/* Expanded Details */}
      {isOpen && (
        <div className="mt-4 pt-4 border-t">
          {segments.map((segment: any, idx: number) => (
            <div key={idx} className="flex items-start space-x-4 mb-4">
              <div className="w-12 text-center">
                <div className="text-sm font-semibold">{formatTime(segment.departure.at)}</div>
                <div className="text-xs text-gray-500">{segment.departure.iataCode}</div>
              </div>
              <div className="flex-1 border-b pb-4">
                <div className="text-sm">
                  {segment.carrierCode} {segment.number}
                </div>
                <div className="text-xs text-gray-500">
                  Durată: {formatDuration(segment.duration)}
                </div>
              </div>
              <div className="w-12 text-center">
                <div className="text-sm font-semibold">{formatTime(segment.arrival.at)}</div>
                <div className="text-xs text-gray-500">{segment.arrival.iataCode}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlightCard;