import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "../type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export interface FlightSearchFormProps { }

export type TypeDropOffLocationType = "roundTrip" | "oneWay" | "";

const FlightSearchForm: FC<FlightSearchFormProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    guestAdults: reduxGuestAdults,
    guestChildren: reduxGuestChildren,
    guestInfants: reduxGuestInfants,
    originLocation: reduxOriginLocation,
    destinationLocation: reduxDestinationLocation,
    flightDateRange: reduxFlightDateRange,
  } = useSelector((state: any) => state.searchStoreModule);

  const [dropOffLocationType, setDropOffLocationType] =
    useState<TypeDropOffLocationType>("roundTrip");


  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);

  const [originLocation, setOriginLocation] = useState(null);
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [flightDateRange, setFlightDateRange] = useState<[Date | null, Date | null]>([null, null]);

  useEffect(() => {
    if (reduxGuestAdults !== null) setGuestAdultsInputValue(reduxGuestAdults);
    if (reduxGuestChildren !== null) setGuestChildrenInputValue(reduxGuestChildren);
    if (reduxGuestInfants !== null) setGuestInfantsInputValue(reduxGuestInfants);
    if (reduxOriginLocation) setOriginLocation(reduxOriginLocation);
    if (reduxDestinationLocation)
      setDestinationLocation(reduxDestinationLocation);
    if (reduxFlightDateRange)
      setFlightDateRange(reduxFlightDateRange);
  }, [
    reduxGuestAdults,
    reduxGuestChildren,
    reduxGuestInfants,
    reduxOriginLocation,
    reduxDestinationLocation,
    reduxFlightDateRange,
  ]);


  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  const renderGuest = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as="button"
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${totalGuests || ""} ${t("searchForm.passengers")}`}</span>
              <ChevronDownIcon
                className={`${open ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-[250px] md:w-full sm:min-w-[340px] ml-[65px] md:ml-0 max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdultsInputValue}
                  onChange={(value) => handleChangeData(value, "guestAdults")}
                  max={10}
                  min={1}
                  label={t("searchForm.adults")}
                  desc={t("searchForm.adultsDescription")}
                />
                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrenInputValue}
                  onChange={(value) => handleChangeData(value, "guestChildren")}
                  max={4}
                  label={t("searchForm.children")}
                  desc={t("searchForm.childrenDescription")}
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestInfantsInputValue}
                  onChange={(value) => handleChangeData(value, "guestInfants")}
                  max={4}
                  label={t("searchForm.infants")}
                  desc={t("searchForm.infantsDescription")}
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto">
          <div
            className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer my-1 ${
              dropOffLocationType === "roundTrip"
                ? "bg-[#2163a7] shadow-black/10 shadow-lg text-white"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            onClick={(e) => setDropOffLocationType("roundTrip")}
          >
            {t("searchForm.roundTrip")}
          </div>
          <div
            className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer my-1 ${
              dropOffLocationType === "oneWay"
                ? "bg-[#2163a7] text-white shadow-black/10 shadow-lg"
                : "border border-neutral-300 dark:border-neutral-700"
            }`}
            onClick={(e) => setDropOffLocationType("oneWay")}
          >
            {t("searchForm.oneWay")}
          </div>
        </div>

        <div className="hidden sm:block self-center border-r border-slate-200 dark:border-slate-700 h-8 mx-3"></div>

        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        {renderRadioBtn()}
        <div className="flex flex-col sm:flex-row rounded-full">
          <LocationInput
            placeHolder={t("searchForm.flyFrom")}
            desc={t("searchForm.flyFromDescription")}
            className="w-full sm:flex-1 border-b sm:border-b-0 border-neutral-100 dark:border-neutral-700"
            onLocationSelect={(location) => setOriginLocation(location)}
          />
          <div className="hidden sm:block self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            onLocationSelect={(location) => setDestinationLocation(location)}
            placeHolder={t("searchForm.flyTo")}
            desc={t("searchForm.flyToDescription")}
            className="w-full sm:flex-1 border-b sm:border-b-0 border-neutral-100 dark:border-neutral-700"
            divHideVerticalLineClass=" -inset-x-0.5"
          />
          <div className="hidden sm:block self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={dropOffLocationType !== "oneWay"}
            className="w-full sm:flex-1"
            originLocation={originLocation}
            destinationLocation={destinationLocation}
            onChangeRange={(range) => setFlightDateRange(range)}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
