import React, { FC, useState } from "react";
import airCanada from 'images/logos/air-canada-logo.jpg'; // import pentru fiecare logo

const AirCompanies: FC = () => {
    const [search, setSearch] = useState("");
    const alphabet = ["TOATE", "A", "B", "E", "F", "G", "H", "L", "P", "R", "T", "V", "W"];
    const companies = [
        { name: "Aegean Airlines", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/commons/9/94/Aegean_Airlines_Logo_2020.png" },
        { name: "Air Canada", offers: "Descoperiți ofertele", logo: airCanada },
        { name: "Air France", offers: "Descoperiți ofertele", logo: "https://logos-world.net/wp-content/uploads/2020/03/Air-France-Logo-2009-2016.jpg" },
        { name: "Arkia", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8o_TBU9ts92rpC5uB5hvZi9wNe7CMw3XzuA&s" },
        { name: "Bees Airlines", offers: "Descoperiți ofertele", logo: "https://assets.planespotters.net/files/airlines/5/bees-airline_427e26_opk.jpg" },
        { name: "El Al Israel Airlines", offers: "Descoperiți ofertele", logo: "https://1000logos.net/wp-content/uploads/2023/05/El-Al-Logo.jpg" },
        { name: "Emirates", offers: "Descoperiți ofertele", logo: "https://logos-world.net/wp-content/uploads/2020/03/Emirates-Symbol.png" },
        { name: "Eurowings", offers: "Descoperiți ofertele", logo: "https://airhex.com/images/airline-logos/alt/eurowings-europe.png" },
        { name: "FLYONE", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ljeg2mAUrwpeBTNcUdWR4BQoORVXROAZfQ&s" },
        { name: "Flydubai", offers: "Descoperiți ofertele", logo: "https://e7.pngegg.com/pngimages/1000/629/png-clipart-logo-flydubai-flight-981-boeing-737-dubai-blue-text.png" },
        { name: "Freebird Airlines", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpyTE-q2vDZhK4DyPsgE9SQgc7UWBBlTH0eg&s" },
        { name: "Georgian Wings", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/en/b/b8/Georgian_Wings_logo.png" },
        { name: "Hi Sky", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/HiSky_Logo.png" },
        { name: "LOT Polish Airlines", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/3d/LOT_Polish_Airlines.svg/2560px-LOT_Polish_Airlines.svg.png" },
        { name: "Lufthansa", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Lufthansa_Logo_2018.svg/2560px-Lufthansa_Logo_2018.svg.png" },
        { name: "Pegasus Airlines", offers: "Descoperiți ofertele", logo: "https://res.cloudinary.com/wego/f_auto,fl_lossy,w_1000,q_auto/v1480072078/flights/airlines_rectangular/PC.png" },
        { name: "Ryanair", offers: "Descoperiți ofertele", logo: "https://logos-world.net/wp-content/uploads/2020/03/Ryanair-Logo-2013-present.jpg" },
        { name: "Tarom", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjXma0w_Btt49bZp5oF5S6rw9Wp91yfW4TmQ&s" },
        { name: "Turkish Airlines", offers: "Descoperiți ofertele", logo: "https://1000logos.net/wp-content/uploads/2020/04/Turkish_Airlines_logo.png" },
        { name: "Vueling", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Logo_Vueling.svg/2560px-Logo_Vueling.svg.png" },
        { name: "Wizz Air", offers: "Descoperiți ofertele", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Wizz_Air_logo.svg/1200px-Wizz_Air_logo.svg.png" },
        { name: "Air Baltic", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQh6LDArJsUUO6Kb9UpuhFHyxhEHmGaArR-ig&s" },
    ];

    const handleLetterClick = (letter: string) => {
        if (letter === "TOATE") {
            setSearch("");
        } else {
            setSearch(letter);
        }
    };

    const filteredCompanies = companies.filter(company =>
        company.name.toLowerCase().startsWith(search.toLowerCase())
    );

    const handleCardClick = (companyName: string) => {
        // Redirecționare către Google, căutând numele companiei
        window.location.assign("https://www.google.com");
    };

    return (
        <div className="bg-gray-100 min-h-screen py-10">
            <div className="container sm:max-w-[75%] mx-auto px-4 md:px-6 py-8">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                    Bilete de avion ieftine cu diferite companii aeriene
                </h1>
                <p className="text-lg sm:text-2xl font-medium text-gray-600 mb-6">
                    Alege compania aeriană din lista de mai jos
                </p>
                <input
                    type="text"
                    placeholder="Începeți să introduceți un nume"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-full p-4 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <div className="flex flex-wrap gap-2 mb-6">
                    {alphabet.map((letter, index) => (
                        <button
                            key={index}
                            onClick={() => handleLetterClick(letter)}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none"
                        >
                            {letter}
                        </button>
                    ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredCompanies.map((company, index) => (
                        <div
                            key={index}
                            className="flex items-center p-6 bg-white border border-gray-200 rounded-lg shadow-sm transition-shadow cursor-pointer hover:shadow-md"
                            onClick={() => handleCardClick(company.name)}
                        >
                            <img
                                src={company.logo}
                                alt={company.name}
                                className="w-16 h-16 object-contain mr-6"
                            />
                            <div>
                                <h3 className="text-xl font-medium text-gray-800">{company.name}</h3>
                                <p className="text-base text-gray-500">{company.offers}</p> 
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default AirCompanies;
