import React, { FC } from "react";

const PageTerms: FC = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <h3 className="text-left text-2xl font-bold mb-6">Termeni și Condiții</h3>
        <div className="text-left space-y-4">
          <p>Bine ați venit pe site-ul HI-FLY, o platformă digitală dedicată vânzării de bilete de avion și pachetelor de servicii turistice. Acești Termeni și Condiții reglementează accesul și utilizarea serviciilor noastre prin intermediul site-ului www.hi-fly.md, precum și drepturile și obligațiile care decurg din utilizarea acestui site.</p>

          <h2 className="text-1xl font-semibold mt-6">1. Datele Companiei: </h2>
          <p>Denumirea: Societatea cu Răspundere Limitată HI-FLY</p>
          <p>IDNO (Cod Fiscal): 1024600005701</p>
          <p>Adresa Juridică și Poștală: Str. Grădina Botanică, Nr. 2A, mun. Chișinău, MD-2002, Republica Moldova</p>
          <p>Reprezentant: Nicolae Gobjila, în calitate de conducător al societății</p>
          <p>Prin accesarea și utilizarea serviciilor noastre, utilizatorii se angajează să respecte Termenii și Condițiile descrise în acest document. Termenii și Condițiile sunt concepute pentru a asigura o experiență sigură și eficientă pentru toți utilizatorii platformei noastre. Vă încurajăm să citiți cu atenție acest document pentru a înțelege obligațiile și drepturile pe care le aveți în calitate de utilizator al serviciilor HI-FLY. Acești termeni ajută la protejarea drepturilor dvs. ca client și clarifică rolul pe care îl avem în oferirea serviciilor noastre. Prin utilizarea site-ului nostru, confirmați că ați înțeles și acceptat să respectați acești Termeni și Condiții în întregime. Dacă nu sunteți de acord cu oricare dintre termenii prezenți, vă rugăm să nu utilizați serviciile și conținutul disponibil pe site-ul HI-FLY. </p>
          <h2 className="text-1xl font-semibold mt-6">2. Noțiuni Definite: </h2>
          <p>Utilizator: Orice persoană care accesează și utilizează site-ul HI-FLY, indiferent dacă efectuează sau nu o achiziție. </p>
          <p>Servicii de Călătorie: Include toate tipurile de servicii disponibile pentru achiziție prin HI-FLY, cum ar fi bilete de avion și pachete de servicii turistice. </p>
          <p>Bilete de avion: Documentul oficial emis, care confirmă că pasagerul menționat are un loc rezervat pe un anumit zbor al unei companii aeriene. Biletul oferă detaliile zborului, cum ar fi data, ora și locul plecării, precum și condițiile aplicabile transportului. </p>
          <p>Pachet de servicii turistice: Combinația prealabilă a cel puțin două tipuri diferite de servicii turistice pentru aceeași călătorie sau vacanță, care durează mai mult de 24 de ore sau include cazare peste noapte. </p>
          <p>Călător: Orice persoană care dorește să încheie, încheie sau este autorizată să beneficieze de serviciile prevăzute de contractul de servicii turistice, și care se obligă să plătească Organizatorului prețul convenit al pachetului de servicii turistice.  </p>
          <p>Rezervare: Actul de a comanda și achiziționa un serviciu oferit de HI-FLY prin intermediul platformei online. </p>
          <p>Eveniment justificator: Evenimente externe, imprevizibile și inevitabile, care împiedică executarea contractului, cum ar fi, dar nelimitându-se la, catastrofe naturale, războaie, acte de terorism, epidemii grave, intervenții guvernamentale etc. </p>
          <p>Politica de Anulare: Condițiile sub care utilizatorii pot renunța la serviciile contractate și condițiile de rambursare sau penalizare aplicabile. </p>
          <p>Date cu Caracter Personal: Orice informații care se referă la o persoană identificată sau identificabilă, utilizate în scopul procesării rezervărilor și gestionării relației cu clienții. </p>

          <h2 className="text-1xl font-semibold mt-6">3. Serviciile Oferite </h2>
          <p>Descrierea Serviciilor Oferite: HI-FLY S.R.L. oferă următoarele servicii prin intermediul platformei sale online, accesibile la adresa www.hi-fly.md: </p>
          <ul className="list-disc ml-6">
            <li>Vânzarea de Bilete de Avion: Utilizatorii pot achiziționa bilete de avion pentru diverse destinații internaționale și locale. Serviciul include opțiuni de filtrare și selecție pentru a ajuta utilizatorii să găsească cele mai convenabile zboruri disponibile în funcție de datele și preferințele lor. </li>
            <li>Pachete de Servicii Turistice: Oferim pachete complet organizate care includ transport, cazare, mese și alte activități turistice. Aceste pachete sunt concepute pentru a oferi o experiență de călătorie completă, cu diverse opțiuni personalizabile pentru a satisface diverse preferințe și bugete. </li>
          </ul>
          <p>Modul de Funcționare al Serviciilor Online:</p>
          <p>Serviciile noastre sunt accesibile prin intermediul site-ului web, care permite utilizatorilor să exploreze, să compare și să achiziționeze serviciile oferite. Platforma este optimizată pentru a oferi o experiență de utilizare eficientă și sigură, cu funcții de căutare avansate și sisteme de rezervare ușor de utilizat. </p>
          <p>Limitările Serviciilor Online: </p>
          <ul className="list-disc ml-6">
            <li>Disponibilitate: Toate serviciile sunt oferite în funcție de disponibilitatea lor la momentul rezervării. Anumite zboruri sau pachete turistice pot fi limitate în perioade de vârf sau din cauza cererii ridicate.  </li>
            <li>Modificări de Preț: Prețurile serviciilor pot fluctua în funcție de factori externi, inclusiv, dar fără a se limita la, schimbările tarifelor de zbor impuse de companiile aeriene și modificările costurilor de cazare. </li>
            <li>Confirmarea Rezervărilor: Rezervările sunt considerate definitive numai după ce utilizatorul a primit o confirmare din partea HI-FLY, care poate dura până la 24 de ore de la solicitarea inițială. </li>
            <li>Condiții de Anulare și Modificare: Rezervările efectuate prin platforma noastră sunt supuse unor politici de anulare și modificare, care pot implica penalități sau costuri suplimentare, conform detaliilor furnizate în procesul de rezervare. </li>
          </ul>
          <h2 className="text-1xl font-semibold mt-6">4. Prețuri și Plăți  </h2>
          <p>Detalii privind Prețurile, Taxele și Alte Costuri Asociate: </p>
          <p>Prețurile Afișate: Toate prețurile pentru biletele de avion și pachetele de servicii turistice sunt afișate pe site-ul HI-FLY în moneda locală sau în valuta specificată și includ toate taxele guvernamentale și suprataxele, cu excepția celor specificate altfel.  </p>
          <p>Costuri Suplimentare: Anumite servicii suplimentare, cum ar fi asigurările de călătorie, selectarea locurilor, bagajele suplimentare sau modificările rezervării, pot implica costuri adiționale, care vor fi clar specificate în procesul de rezervare. </p>
          <p>Variații de Preț: Prețurile pot varia în funcție de disponibilitate, modificările cererii, și promoții speciale. Odată ce o rezervare este finalizată și confirmată, prețul plătit este garantat, cu excepția modificărilor solicitate de către client.  </p>
          <p>Modalități de Plată Acceptate și Procedurile de Facturare: </p>
          <p>Modalități de Plată: HI-FLY acceptă mai multe metode de plată, inclusiv carduri de credit/debit (Visa, MasterCard, American Express), plata prin transfer bancar, și alte metode de plată electronică specifice fiecărei țări. </p>
          <p>Securitatea Plăților: Toate tranzacțiile sunt securizate prin tehnologie de criptare, iar datele cardului de credit nu sunt stocate pe serverele noastre pentru a asigura confidențialitatea și securitatea informațiilor dumneavoastră. </p>
          <p>Facturarea: Odată cu finalizarea plății, utilizatorul va primi o confirmare prin e-mail care include detalii ale tranzacției și ale serviciilor achiziționate. Facturile detaliate pot fi solicitate prin contactarea serviciului nostru de asistență pentru clienți. </p>
          <p>Rambursările și Anulările: Politica de rambursare a HI-FLY este conformă cu termenii specificați în secțiunea de Anulare și Rambursare a Termenilor și Condițiilor. Detalii specifice legate de condițiile de rambursare vor fi furnizate în procesul de anulare. </p>

          <h2 className="text-1xl font-semibold mt-6">5. Politica de Anulare și Rambursare</h2>
          <p>Condiții sub care utilizatorii pot anula serviciile achiziționate: Anulare Inițiată de Călător: Utilizatorii pot anula rezervările efectuate pe site-ul HI-FLY conform următoarelor condiții: </p>
          <ul className="list-disc ml-6">
            <li>Pentru biletele de avion, condițiile de anulare pot varia în funcție de tariful ales și de politica companiei aeriene. Detaliile specifice vor fi furnizate în momentul rezervării. </li>
            <li>Pentru pachetele de servicii turistice rezervate prin sistemul de early-booking, anulările trebuie efectuate cu cel puțin 60 zile înainte de data începerii serviciului pentru a evita penalitățile majore.  </li>
            <li>Anulările efectuate cu mai puțin de 60 zile înainte de data de începere a serviciului pot atrage penalități care variază de la 50% până la 100% din costul total al serviciului. </li>
            <li>Pentru pachetele de servicii turistice rezervate înafara sistemului de early-booking, penalitățile aplicate pentru anularea serviciilor depind de la caz la caz și vor fi incluse în Contractul de servicii turistice.  </li>
          </ul>
          <p>Procedura de rambursare a sumelor plătite: </p>
          <p>Procesul de Rambursare: Odată ce anularea a fost confirmată și acceptată conform termenilor de mai sus, procesul de rambursare va începe. Suma rambursabilă va fi creditată pe aceeași metodă de plată utilizată la achiziție, cu excepția cazurilor în care se specifică altfel. </p>
          <p>Rambursările pentru biletele de avion sunt supuse regulilor companiei aeriene și pot include taxe de procesare sau penalități, în funcție de tariful biletului. </p>
          <p>Pentru pachetele turistice, suma rambursată va reflecta penalitățile aplicate și orice costuri neacoperite care nu pot fi recuperate de la furnizorii de servicii. </p>
          <p>Timpul de Procesare: Rambursările sunt procesate într-un termen rezonabil, în dependență de viteza de procesare din partea companiei aeriene și a altor furnizori de servicii. Timpul exact poate varia în funcție de instituția financiară implicată. </p>

          <h2 className="text-1xl font-semibold mt-6">6. Drepturi de Proprietate Intelectuală</h2>
          <p>Drepturi de Autor și Proprietate Intelectuală: </p>
          <p>Conținut Protejat: Tot conținutul prezent pe site-ul HI-FLY, inclusiv textele, grafica, logo-urile, imaginile, clipurile video, designul site-ului și software-ul, este proprietatea HI-FLY S.R.L. sau este utilizat sub licență de la furnizori terți. Acest conținut este protejat de legile Republicii Moldova și de convențiile internaționale privind drepturile de autor. </p>
          <p>Utilizarea Conținutului: Conținutul de pe site este oferit doar pentru informare și uz personal, ne-comercial al utilizatorilor. Este interzisă copierea, reproducerea, redistribuirea, publicarea sau folosirea oricărui conținut fără permisiunea scrisă explicită a HI-FLY S.R.L. oricărei alte acțiuni care ar putea încălca drepturile de proprietate intelectuală ale HI-FLY sau ale terților. </p>
          <p>Protecția Mărcilor Comerciale: </p>
          <p>Mărci Comerciale: Logo-urile și mărcile comerciale afișate pe site sunt mărci înregistrate și nu pot fi utilizate în legătură cu niciun produs sau serviciu care nu este parte a HI-FLY S.R.L., în nicio manieră care ar putea crea confuzie printre consumatori, sau în nicio manieră care denigrează sau discreditează HI-FLY S.R.L. </p>
          <p>Încălcări ale Drepturilor de Proprietate Intelectuală: </p>
          <p>Utilizatorii sunt încurajați să raporteze orice încălcare a drepturilor de proprietate intelectuală observate în legătură cu conținutul de pe HI-FLY. HI-FLY S.R.L. se angajează să investigheze astfel de rapoarte și să ia măsurile corespunzătoare pentru a adresa orice încălcări. </p>

          <h2 className="text-1xl font-semibold mt-6">7. Responsabilitatea Utilizatorilor </h2>
          <p>Obligațiile Utilizatorilor în Ceea ce Privește Veridicitatea și Legalitatea Informațiilor Furnizate: </p>
          <p>Informații Acurate: Utilizatorii sunt responsabili pentru acuratețea, integritatea și actualitatea informațiilor pe care le furnizează atunci când creează un cont, efectuează o rezervare sau utilizează orice alt serviciu oferit de HI-FLY. Informațiile eronate pot duce la imposibilitatea de a beneficia de serviciile rezervate, inclusiv anularea biletelor sau a pachetelor turistice fără posibilitatea de rambursare. </p>
          <p>Legalitate: Utilizatorii trebuie să asigure că informațiile furnizate nu încalcă nicio lege sau reglementare aplicabilă. Aceasta include evitarea furnizării de informații false sau înșelătoare și neutilizarea datelor obținute de la terți fără consimțământul explicit al acestora. </p>

          <h2 className="text-1xl font-semibold mt-6">8. Limitări de Răspundere </h2>
          <p>Limitările Răspunderii HI-FLY: </p>
          <p>Performanța Serviciilor: HI-FLY depune eforturi constante pentru a asigura acuratețea, legalitatea și funcționalitatea serviciilor sale, însă nu garantează că serviciile site-ului vor funcționa neîntrerupt sau fără erori. HI-FLY nu va fi răspunzătoare pentru întreruperile de serviciu care nu sunt direct sub controlul său. </p>
          <p>Daune Consecvente: În nicio circumstanță HI-FLY nu va fi responsabilă pentru orice daune indirecte, speciale, consecvente, exemplare sau punitive care ar putea rezulta din utilizarea sau incapacitatea de a utiliza serviciile sau informațiile disponibile pe site. </p>
          <p>Interacțiuni cu Terți: Site-ul HI-FLY poate include linkuri către servicii terțe, iar interacțiunea utilizatorilor cu aceste servicii externe este supusă termenilor și condițiilor entităților respective. HI-FLY nu are control și nu își asumă răspunderea pentru conținutul sau practicile acestor site-uri terțe. </p>
          <p>Limita Răspunderii Financiare: Răspunderea totală a HI-FLY, indiferent de circumstanțe, nu va depăși suma efectiv plătită de client către HI-FLY pentru serviciul specific care a generat cererea de răspundere. </p>

          <h2 className="text-1xl font-semibold mt-6">9. Politica de Confidențialitate </h2>
          <p>Referință către Politica de Confidențialitate: </p>
          <p>Documentul complet al Politicii de Confidențialitate poate fi accesat http://hi-fly.md/privacy. Acest document detaliază toate aspectele legate de prelucrarea datelor personale de către HI-FLY, inclusiv cum sunt colectate, stocate, protejate și pentru ce scopuri sunt folosite aceste date. </p>

          <h2 className="text-1xl font-semibold mt-6">10. Modificări ale Termenilor și Condițiilor  </h2>
          <p>Condițiile în care acești Termeni pot fi modificați:</p>
          <p>Necesitatea de Adaptare: HI-FLY își rezervă dreptul de a modifica Termenii și Condițiile în orice moment pentru a reflecta schimbări în legislația aplicabilă, evoluții ale serviciilor oferite sau pentru a aborda noi probleme de securitate sau confidențialitate. </p>
          <p>Decizia Internă: Modificările pot fi, de asemenea, făcute pentru a îmbunătăți claritatea textului sau pentru a ajusta politicile în funcție de feedback-ul utilizatorilor sau de schimbările în structura de afaceri a HI-FLY. </p>
          <p>Comunicarea Modificărilor: </p>
          <p>Notificare Prealabilă: În cazul modificărilor semnificative ale Termenilor și Condițiilor, HI-FLY va informa utilizatorii prin e-mail. </p>
          <p>Publicarea pe Site: Toate modificările vor fi, de asemenea, publicate în secțiunea relevantă a site-ului HI-FLY, iar data la care aceste modificări intră în vigoare va fi clar indicată. </p>
          <p>Consimțământ Implicit: Continuarea utilizării site-ului și a serviciilor HI-FLY după intrarea în vigoare a modificărilor va constitui acceptul implicit al utilizatorului față de noii Termeni și Condiții. Dacă un utilizator nu este de acord cu modificările propuse, are dreptul de a înceta utilizarea site-ului și serviciilor. </p>

          <h2 className="text-1xl font-semibold mt-6">11. Proceduri de Soluționare a Disputelor  </h2>
          <p>Mecanisme Disponibile pentru Soluționarea Disputelor și Reclamațiilor: </p>
          <p>Asistență pentru Client: În cazul apariției unor probleme sau nelămuriri legate de serviciile HI-FLY, utilizatorii sunt încurajați să contacteze inițial serviciul de asistență pentru clienți.</p>
          <p>HI-FLY se angajează să răspundă prompt și să ofere soluții adecvate pentru a adresa și rezolva reclamațiile într-un mod satisfăcător pentru ambele părți. </p>
          <p>Mediere: Dacă discuțiile directe cu serviciul de asistență pentru clienți nu rezolvă problema, utilizatorii pot solicita medierea disputelor. Aceasta implică participarea unei terțe părți neutre, un mediator, care să ajute la găsirea unei soluții convenabile pentru ambele părți.</p>
          <p>Costurile medierii vor fi împărțite egal între utilizator și HI-FLY, cu excepția cazului în care părțile convin altfel. </p>
          <p>Recurs la Instanțele Judecătorești: Ca ultimă soluție, disputa poate fi adusă în fața instanțelor judecătorești competente. Jurisdicția și locul de desfășurare a procedurilor judiciare vor fi determinate în conformitate cu adresa legală a HI-FLY și legislația aplicabilă a Republicii Moldova.  </p>
          <p>Notificarea și Comunicarea Disputelor: </p>
          <p>Toate notificările legate de dispute sau reclamații trebuie să fie făcute în scris și trimise la adresa de contact, sau de e-mail, specificată pe site-ul HI-FLY. HI-FLY se angajează să revizuiască și să răspundă la toate notificările într-un termen rezonabil. </p>

          <h2 className="text-1xl font-semibold mt-6">12. Legislația Aplicabilă și Jurisdicția  </h2>
          <p>Legislația Aplicabilă: </p>
          <p>Termenii și Condițiile, precum și orice dispute sau revendicări care decurg din sau în legătură cu acestea, inclusiv existența, validitatea, interpretarea, performanța, încălcarea sau terminarea acestora, vor fi interpretate și guvernate în conformitate cu legile Republicii Moldova. </p>
          <p>Jurisdicția pentru Soluționarea Litigiilor: </p>
          <p>Pentru soluționarea oricăror litigii care apar în legătură cu utilizarea serviciilor site-ului HI-FLY, care nu pot fi rezolvate prin mediere sau arbitraj, părțile se vor adresa instanțelor competente din Republica Moldova. </p>
          <p>HI-FLY și utilizatorul sunt de acord să se supună jurisdicției exclusive a tribunalelor din Republica Moldova pentru soluționarea oricăror dispute care pot apărea sub Termenii și Condițiile acestui site. </p>
          <p>Recunoașterea Jurisdicției: </p>
          <p>Prin acceptarea acestor Termeni și Condiții, utilizatorii recunosc și acceptă că orice acțiuni legale îndreptate împotriva HI-FLY vor fi inițiate și vor fi purtate în tribunalele din Republica Moldova. Această recunoaștere se aplică indiferent de domiciliul sau locația fizică a utilizatorului la momentul utilizării serviciilor HI-FLY sau la momentul apariției disputelor. </p>
          <p>Competența Exclusivă: </p>
          <p>Această clauză de jurisdicție nu împiedică HI-FLY să inițieze acțiuni legale împotriva unui utilizator în orice altă jurisdicție competentă, iar inițierea unor astfel de acțiuni în alte jurisdicții nu va constitui o renunțare la drepturile sale de a menține acțiuni în Republica Moldova. </p>

          <h2 className="text-1xl font-semibold mt-6">13. Contact   </h2>
          <p>Modalități de Contactare a HI-FLY: </p>
          <p>Email: Utilizatorii pot trimite orice întrebări sau cereri la adresa de email dedicată suportului clienți: support@hi-fly.md  </p>
          <p>Telefon: Pentru asistență directă, utilizatorii pot apela numărul de telefon al serviciului clienți: +373 68 70 70 72. Linia telefonică este deschisă în zilele lucrătoare, de la ora 9:00 la ora 18:00. </p>
          <p>Adresă Poștală: Pentru corespondență oficială sau documentație care necesită trimitere fizică, utilizatorii pot trimite materialele la adresa juridică a companiei: Str. Grădina Botanică, Nr. 2A, mun. Chișinău, MD-2002, Republica Moldova. </p>
          <p>Vă mulțumim pentru că ați ales HI-FLY pentru serviciile dumneavoastră de călătorie. </p>
        </div>
      </div>
    </div>
  );
};

export default PageTerms;
