import React, { FC } from "react";
import Button from "shared/Button/Button";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
import searchBg from 'assets/images/search_bg.png';
import { useTranslation } from "react-i18next";

export interface SectionSubscribe2Props {
  className?: string;
}
const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center justify-between bg-neutral-800 text-white p-8 rounded-lg overflow-visible sm:mt-32 ${className}`}
      data-nc-id="SectionSubscribe2"
      style={{
        backgroundImage: `url(${searchBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }}
    >
      <div className="absolute w-full h-full left-0 top-0 bg-[#0063a7] z-1 rounded-lg opacity-25"></div>
      {/* Text Content */}
      <div className="flex flex-col lg:w-1/2 lg:ml-10 relative z-5">
        <h2 className="text-3xl font-semibold">{t("newsletter.title")}</h2>
        <p className="mt-4 text-neutral-300">
          {t("newsletter.description")}
        </p>
        {/* Subscription Form */}
        <form className="mt-6 flex items-center w-full max-w-md md:flex-row flex-col">
          <Input
            required
            aria-required
            placeholder={t("newsletter.placeholder")}
            type="email"
            className="flex-grow px-4 py-2 rounded-[5px]"
          />
          <Button
            type="submit"
            className="bg-[#0063a7] text-white px-12 !py-2.5 md:ml-2 !rounded-[5px] hover:bg-blue-700 shadow-md text-nowrap w-full md:w-auto md:mt-0 mt-3"
          >
            {t("newsletter.button")}
          </Button>
        </form>
      </div>

      {/* Image Section */}
      <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center overflow-visible">
        <NcImage src={rightImg} className="lg:w-[40%] w-full h-auto lg:absolute lg:bottom-0 lg:right-[-40px] relative z-5" />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
