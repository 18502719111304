import React, { useState } from 'react';
import { MessageCircle, Phone, MessageCircleMore } from 'lucide-react';
import { BrandWhatsapp, BrandTelegram } from 'components/SocialWidget/Icons';

const SocialWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const socialLinks = [
    { icon: <Phone size={28} />, href: 'tel:+37368707072', bg: 'bg-blue-500' },
    { icon: <BrandWhatsapp size={28} />, href: 'https://wa.me/37368707072', bg: 'bg-green-500' },
    { icon: <MessageCircleMore size={28} />, href: 'viber://chat?number=+37368707072', bg: 'bg-purple-500' },
    { icon: <BrandTelegram size={28} />, href: 'https://t.me/+37368707072', bg: 'bg-sky-500' },
  ];

  return (
    <div
      className={`fixed ${
        window.innerWidth < 640 ? 'bottom-20' : 'bottom-8'
      } right-8 z-50`}
    >
      <div className="relative">
        {/* Social Icons */}
        <div
          className={`absolute bottom-full right-0 mb-4 space-y-3 transition-all duration-300 ${
            isOpen
              ? 'opacity-100 translate-y-0'
              : 'opacity-0 translate-y-4 pointer-events-none'
          }`}
        >
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className={`${link.bg} w-16 h-16 rounded-full flex items-center justify-center text-white shadow-lg hover:scale-110 transition-transform duration-200`}
            >
              {link.icon}
            </a>
          ))}
        </div>

        {/* Main Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`w-16 h-16 bg-[#0063a7] rounded-full flex items-center justify-center text-white shadow-lg hover:bg-indigo-700 transition-all duration-300 ${
            isOpen ? 'rotate-45' : ''
          }`}
        >
          <MessageCircle size={28} />
        </button>
      </div>
    </div>
  );
};

export default SocialWidget;
