import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './root.reducer';

const persistConfig = {
    key: 'hiflyStorage',
    storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Dezactivează serializableCheck pentru persist
        }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => store.dispatch;
export type AppThunk = ThunkAction<void, any, unknown, Action>;

export const persistor = persistStore(store);

export default store;
