import React, { FC } from "react";
import { useSelector } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  // Extrage datele despre zbor din Redux
  const { flyItem } = useSelector((state: any) => state.checkoutStoreModule);
  const segment = flyItem.itineraries[0].segments[0];
  const price = flyItem.price;

  const renderContent = () => {
    return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <h2 className="text-3xl lg:text-4xl font-semibold">Felicitări 🎉</h2>
          <p className="text-neutral-500">
            Rezervarea dvs. a fost finalizată cu succes!
          </p>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* ------------------------ */}
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold">Detalii despre zbor</h3>
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden bg-gray-100">
                  <img
                      src="https://via.placeholder.com/150"
                      alt="Zbor"
                      className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="pt-5 sm:pb-5 sm:px-5 space-y-3">
                <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  Zbor de la {segment.departure.iataCode} către{" "}
                  {segment.arrival.iataCode}
                </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                  Operat de {segment.carrierCode}
                </span>
                </div>
                <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                Plecare:{" "}
                  {new Date(segment.departure.at).toLocaleString("ro-RO", {
                    weekday: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                    day: "2-digit",
                    month: "long",
                  })}
              </span>
                <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                Sosire:{" "}
                  {new Date(segment.arrival.at).toLocaleString("ro-RO", {
                    weekday: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                    day: "2-digit",
                    month: "long",
                  })}
              </span>
                <StartRating />
              </div>
            </div>

            {/* ------------------------ */}
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold">Detalii Rezervare</h3>
              <div className="flex flex-col space-y-4">
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Cod rezervare</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  #123-456-789
                </span>
                </div>
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Data zborului</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {new Date(segment.departure.at).toLocaleDateString("ro-RO")}
                </span>
                </div>
                <div className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Durata</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {flyItem.itineraries[0].duration}
                </span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Total</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {price.currency} {price.grandTotal}
                </span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">Metodă de plată</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  Card de credit
                </span>
                </div>
              </div>
            </div>

            <div>
              <ButtonPrimary href="/">Descoperă alte zboruri</ButtonPrimary>
            </div>
          </div>
        </div>
    );
  };

  return (
      <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
        <main className="container mt-11 mb-24 lg:mb-32 ">
          <div className="max-w-4xl mx-auto">{renderContent()}</div>
        </main>
      </div>
  );
};

export default PayPage;
