import React from "react";
import { useTranslation } from "react-i18next";

const CountryList: React.FC = () => {
  const { t } = useTranslation();

  const countries = [
    { id: 1, name: t("countries.romania"), link: "#" },
    { id: 2, name: t("countries.italy"), link: "#" },
    { id: 3, name: t("countries.france"), link: "#" },
    { id: 4, name: t("countries.germany"), link: "#" },
    { id: 5, name: t("countries.spain"), link: "#" },
    { id: 6, name: t("countries.poland"), link: "#" },
    { id: 7, name: t("countries.hungary"), link: "#" },
    { id: 8, name: t("countries.bulgaria"), link: "#" },
    { id: 9, name: t("countries.greece"), link: "#" },
    { id: 10, name: t("countries.austria"), link: "#" },
    { id: 11, name: t("countries.czechRepublic"), link: "#" },
    { id: 12, name: t("countries.slovakia"), link: "#" },
    { id: 13, name: t("countries.croatia"), link: "#" },
    { id: 14, name: t("countries.serbia"), link: "#" },
    { id: 15, name: t("countries.netherlands"), link: "#" },
    { id: 16, name: t("countries.belgium"), link: "#" },
    { id: 17, name: t("countries.uk"), link: "#" },
    { id: 18, name: t("countries.portugal"), link: "#" },
    { id: 19, name: t("countries.sweden"), link: "#" },
    { id: 20, name: t("countries.norway"), link: "#" },
    { id: 21, name: t("countries.denmark"), link: "#" },
    { id: 22, name: t("countries.finland"), link: "#" },
    { id: 23, name: t("countries.ireland"), link: "#" },
    { id: 24, name: t("countries.lithuania"), link: "#" },
    { id: 25, name: t("countries.latvia"), link: "#" },
    { id: 26, name: t("countries.estonia"), link: "#" },
    { id: 27, name: t("countries.luxembourg"), link: "#" },
    { id: 28, name: t("countries.slovenia"), link: "#" },
    { id: 29, name: t("countries.malta"), link: "#" },
    { id: 30, name: t("countries.switzerland"), link: "#" },
    { id: 31, name: t("countries.liechtenstein"), link: "#" },
    { id: 32, name: t("countries.turkey"), link: "#" },
    { id: 33, name: t("countries.russia"), link: "#" },
    { id: 34, name: t("countries.china"), link: "#" },
  ];

  return (
    <div className="py-8">
      <div className="container mx-auto px-2">
        {/* Titlu */}
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold">
          {t('countryList.destinationsTitle')}
        </h2>
        {/* Descriere */}
        <p className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
          {t('countryList.destinationsDescription')}
        </p>

        {/* Lista de țări */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 mt-4">
          {countries.map((country) => (
            <div
              key={country.id}
              className="text-sm sm:text-base text-gray-800 underline cursor-pointer"
            >
              <a href={country.link}>{country.name}</a>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default CountryList;
