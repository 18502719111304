import React from 'react';
import { Range } from 'react-range';

interface TimeSelectorProps {
  minDuration: number; // Durata minimă (1 oră)
  maxDuration: number; // Durata maximă (24 ore)
  valuesDeparture: number[]; // Valorile curente pentru decolare
  valuesLanding: number[]; // Valorile curente pentru aterizare
  onChangeDeparture: (values: number[]) => void; // Funcția de schimbare a valorii slider-ului de decolare
  onChangeLanding: (values: number[]) => void; // Funcția de schimbare a valorii slider-ului de aterizare
  onReset?: () => void; // Funcția de resetare a slider-urilor
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  minDuration,
  maxDuration,
  valuesDeparture,
  valuesLanding,
  onChangeDeparture,
  onChangeLanding,
  onReset
}) => {
  const [initialValuesDeparture] = React.useState<number[]>(valuesDeparture);  // Salvăm valorile inițiale pentru decolare
  const [initialValuesLanding] = React.useState<number[]>(valuesLanding);  // Salvăm valorile inițiale pentru aterizare

  // Verificăm dacă s-au făcut modificări pentru fiecare slider
  const isDepartureModified = valuesDeparture[0] !== initialValuesDeparture[0] || valuesDeparture[1] !== initialValuesDeparture[1];
  const isLandingModified = valuesLanding[0] !== initialValuesLanding[0] || valuesLanding[1] !== initialValuesLanding[1];

  // Calculăm proporțiile corecte pentru a aplica gradienții pe fiecare slider
  const leftPercentageDeparture = ((valuesDeparture[0] - minDuration) / (maxDuration - minDuration)) * 100;
  const rightPercentageDeparture = ((valuesDeparture[1] - minDuration) / (maxDuration - minDuration)) * 100;
  const leftPercentageLanding = ((valuesLanding[0] - minDuration) / (maxDuration - minDuration)) * 100;
  const rightPercentageLanding = ((valuesLanding[1] - minDuration) / (maxDuration - minDuration)) * 100;

  // Stilul de fundal pentru fiecare slider
  const backgroundStyleDeparture = {
    background: `linear-gradient(to right, #e5e7eb ${0}%, #e5e7eb ${leftPercentageDeparture}%, 
                #1e40af ${leftPercentageDeparture}%, #1e40af ${rightPercentageDeparture}%, 
                #e5e7eb ${rightPercentageDeparture}%, #e5e7eb 100%)`
  };

  const backgroundStyleLanding = {
    background: `linear-gradient(to right, #e5e7eb ${0}%, #e5e7eb ${leftPercentageLanding}%, 
                #1e40af ${leftPercentageLanding}%, #1e40af ${rightPercentageLanding}%, 
                #e5e7eb ${rightPercentageLanding}%, #e5e7eb 100%)`
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <h4 className="text-l font-bold">Timp</h4>
        {isDepartureModified || isLandingModified && onReset && (
          <button 
            onClick={onReset}
            className="text-blue-700 font-medium text-sm hover:text-blue-800"
          >
            Resetează
          </button>
        )}
      </div>

      {/* Selector pentru Decolare */}
      <div className="space-y-4">
        <h5 className="text-sm font-medium">Decolare</h5>
        <div className="px-2">
          <Range
            step={1}
            min={minDuration}
            max={maxDuration}
            values={valuesDeparture}
            onChange={onChangeDeparture}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="h-1 w-full rounded-full"
                style={backgroundStyleDeparture} // Aplicăm fundalul pentru decolare
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="h-5 w-5 bg-white border-2 border-blue-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
        </div>
        <div className="flex justify-between items-center text-sm">
          <span className="font-medium">{valuesDeparture[0]} ore</span>
          <span className="font-medium">{valuesDeparture[1]} ore</span>
        </div>
      </div>

      {/* Selector pentru Aterizare */}
      <div className="space-y-4">
        <h5 className="text-sm font-medium">Aterizare</h5>
        <div className="px-2">
          <Range
            step={1}
            min={minDuration}
            max={maxDuration}
            values={valuesLanding}
            onChange={onChangeLanding}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="h-1 w-full rounded-full"
                style={backgroundStyleLanding} // Aplicăm fundalul pentru aterizare
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="h-5 w-5 bg-white border-2 border-blue-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
          />
        </div>
        <div className="flex justify-between items-center text-sm">
          <span className="font-medium">{valuesLanding[0]} ore</span>
          <span className="font-medium">{valuesLanding[1]} ore</span>
        </div>
      </div>
    </div>
  );
};

export default TimeSelector;
