import { combineReducers } from '@reduxjs/toolkit';
import SearchReducer from "./modules/search.reducer";
import CheckoutReducer from "./modules/checkout.reducer";

const rootReducer = combineReducers({
        searchStoreModule: SearchReducer,
        checkoutStoreModule: CheckoutReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
