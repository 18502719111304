import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import {searchCities} from "../../services/search.service";


export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  defaultValue?: any
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onLocationSelect?: (location: any) => void;
}

const LocationInput: FC<LocationInputProps> = ({
                                                 autoFocus = false,
                                                 placeHolder = "Location",
                                                 desc = "Where are you going?",
                                                 defaultValue,
                                                 className = "nc-flex-1.5",
                                                 divHideVerticalLineClass = "left-10 -right-0.5",
                                                 onChange,
                                                 onLocationSelect
                                               }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue ?? "");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("click", eventClickOutsideDiv);
    } else {
      document.removeEventListener("click", eventClickOutsideDiv);
    }

    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    onChange && onChange(newValue);

    if (newValue.length > 2) {
      const response = await searchCities(newValue);
      setSearchResults(response?.data || []);
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectLocation = (cityName: string, airportName: string) => {
    const location = `${cityName} + ${airportName}`;
    setValue(location);
    setShowPopover(false);
    onLocationSelect &&
    onLocationSelect({
      aerportId: airportName,
      cityName: cityName,
    })
    onChange && onChange(location);
  };

  const renderSearchResults = () => {
    return searchResults.map((result) => {
      if(result?.airports?.length === 0) return null;
      return (
          <div key={result?.city?.id} className="px-4 py-2">
            {/* Orașul */}
            <div className="flex items-center space-x-2 font-medium text-neutral-700 dark:text-neutral-200">
              <MapPinIcon className="w-5 h-5 text-blue-500"/>
              <span>[{result?.city?.address.countryCode}] {result?.city?.name}</span>
            </div>
            {/* Aeroporturile */}
            <div className="pl-8 mt-2">
              {result?.airports?.map((airport: any) => (
                  <div
                      key={airport.id}
                      onClick={() => handleSelectLocation(result?.city?.name, airport.iataCode)}
                      className="flex items-center space-x-2 cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700 px-2 py-1 rounded"
                  >
                    <ClockIcon className="w-4 h-4 text-neutral-400"/>
                    <span className="text-sm text-neutral-600 dark:text-neutral-400">
                {airport.name} ({airport.iataCode})
              </span>
                  </div>
              ))}
            </div>
          </div>
      )
    });
  };

  return (
      <div className={`relative flex ${className}`} ref={containerRef}>
        <div
            onClick={() => setShowPopover(true)}
            className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
                showPopover ? "nc-hero-field-focused" : ""
            }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7"/>
          </div>
          <div className="flex-grow">
            <input
                className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
                placeholder={placeHolder}
                value={value}
                autoFocus={showPopover}
                onChange={handleInputChange}
                ref={inputRef}
            />
            <span className="block mt-0.5 text-sm text-neutral-400 font-light">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
            {value && showPopover && (
                <ClearDataButton
                    onClick={() => {
                      setValue("");
                      onChange && onChange("");
                    }}
                />
            )}
          </div>
        </div>

        {showPopover && (
            <div
                className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
            ></div>
        )}

        {showPopover && (
            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
              {searchResults.length > 0 ? renderSearchResults() : <p className="px-4 py-2"></p>}
            </div>
        )}
      </div>
  );
};

export default LocationInput;
