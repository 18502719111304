import React from "react";
import { useTranslation } from "react-i18next";

const AirlineSlider: React.FC = () => {
  const { t } = useTranslation();
  const airlines = [
    { id: 1, name: "FLYONE", link: "#" },
    { id: 2, name: "Aegean Airlines", link: "#" },
    { id: 3, name: "LOT Polish Airlines", link: "#" },
    { id: 4, name: "Eurowings", link: "#" },
    { id: 5, name: "Tarom", link: "#" },
    { id: 6, name: "Pegasus Airlines", link: "#" },
    { id: 7, name: "Georgian Wings", link: "#" },
    { id: 8, name: "HiSky", link: "#" },
    { id: 9, name: "Turkish Airlines", link: "#" },
    { id: 10, name: "Freebird Airlines", link: "#" },
    { id: 11, name: "El Al Israel Airlines", link: "#" },
    { id: 12, name: "airBaltic", link: "#" },
    { id: 13, name: "Wizz Air", link: "#" },
    { id: 14, name: "Lufthansa", link: "#" },
    { id: 15, name: "Bees Airlines", link: "#" },
    { id: 16, name: "Flydubai", link: "#" },
    { id: 17, name: "Vueling", link: "#" },
    { id: 18, name: "Ryanair", link: "#" },
    { id: 19, name: "Emirates", link: "#" },
    { id: 20, name: "Air France", link: "#" },
    { id: 21, name: "Air Canada", link: "#" },
    { id: 22, name: "Arkia", link: "#" },
    { id: 23, name: "RyanAir", link: "#" },
    { id: 24, name: "Sky Up Airlines", link: "#" },
  ];

  return (
    <div className="py-8">
      <div className="container mx-auto px-2 text-left mb-2 sm:mb-4">
        {/* Titlu */}
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold">
          {t("airlines.title")}
        </h2>
        {/* Descriere */}
        <p className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
          {t("airlines.description")}
        </p>
        {/* Lista companiilor aeriene */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 mt-4">
          {airlines.map((airline) => (
            <div
              key={airline.id}
              className="text-sm sm:text-base text-gray-800 underline cursor-pointer"
            >
              <a href={airline.link}>{airline.name}</a>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default AirlineSlider;
