import React from 'react';

export const BrandWhatsapp = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M3 21l1.65-3.8a9 9 0 1 1 3.4 2.9L3 21" />
    <path d="M9 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0v1a5 5 0 0 0 5 5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 0 1" />
  </svg>
);

export const BrandTelegram = ({ size = 24, ...props }) => (
    <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 3.18L19.66 20.1c-.18.92-.66 1.14-1.34.75l-5.38-3.96-2.59 2.5c-.29.29-.54.54-1.11.54l.4-5.73L18.6 6.48c.4-.36-.08-.57-.62-.38L5.88 10.6l-5.54-1.72c-1.2-.38-1.22-.88.24-1.33L21.35 2.05c.96-.32 1.8.24 1.48 1.13h-.33Z"
      fill="currentColor"
    />
  </svg>
);