import React, { FC } from "react";

const PageIATA: FC = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <h3 className="text-left text-2xl font-bold mb-6">Regulamentul IATA</h3>
        <div className="text-left space-y-4">
          <p>Acest regulament este emis în conformitate cu standardele și cerințele stabilite de Asociația Internațională a Transportului Aerian (IATA). Utilizarea serviciilor Hi Fly implică acceptarea termenilor și condițiilor prevăzute mai jos.</p>

          <h2 className="text-1xl font-semibold mt-6">1. Condiții Generale</h2>
          <p>Toate rezervările efectuate prin Hi Fly sunt supuse reglementărilor IATA. Este responsabilitatea pasagerului să respecte politicile companiilor aeriene în ceea ce privește transportul, modificările și anulările.</p>

          <h2 className="text-1xl font-semibold mt-6">2. Emiterea Biletelor</h2>
          <p>Biletele emise de Hi Fly respectă standardele IATA. Orice eroare în informațiile furnizate la momentul rezervării poate duce la anularea sau modificarea biletului, cu costuri suplimentare suportate de pasager.</p>

          <h2 className="text-1xl font-semibold mt-6">3. Politica de Modificare și Anulare</h2>
          <p>Modificările și anulările sunt gestionate conform termenilor și condițiilor companiei aeriene. Hi Fly oferă suport pentru gestionarea acestor solicitări, dar nu este responsabilă pentru taxele aplicate de furnizori.</p>

          <h2 className="text-1xl font-semibold mt-6">4. Transportul Bagajelor</h2>
          <p>Regulile privind bagajele sunt stabilite de fiecare companie aeriană. Pasagerii sunt responsabili să verifice și să respecte limitele și restricțiile impuse de furnizori.</p>

          <h2 className="text-1xl font-semibold mt-6">5. Drepturi și Obligații</h2>
          <p>Pasagerii au dreptul să primească informații clare despre rezervări și condițiile de călătorie. Este obligația lor să se prezinte la aeroport cu suficient timp înainte de decolare și să aibă documentele necesare de călătorie.</p>

          <h2 className="text-1xl font-semibold mt-6">6. Responsabilitatea Hi Fly</h2>
          <p>Hi Fly acționează ca intermediar între pasageri și companiile aeriene. Nu ne asumăm răspunderea pentru întârzieri, anulări sau alte probleme apărute în timpul călătoriei, care sunt responsabilitatea directă a companiilor aeriene.</p>

          <h2 className="text-1xl font-semibold mt-6">7. Modificări ale Regulamentului</h2>
          <p>Hi Fly își rezervă dreptul de a modifica regulamentul în orice moment, în conformitate cu actualizările IATA. Vă recomandăm să verificați această pagină periodic pentru cele mai recente informații.</p>

          <h2 className="text-1xl font-semibold mt-6">8. Contact</h2>
          <p>Pentru întrebări legate de regulamentul IATA sau pentru suport, vă rugăm să ne contactați la adresa de email support@hifly.md sau telefonic la +373 68 707 072.</p>
        </div>
      </div>
    </div>
  );
};

export default PageIATA;
