import React from 'react';
import { Mail, Phone } from 'lucide-react';
import logo from 'assets/images/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const widgetMenus = [
  {
    id: "1",
    title: "Hi_Fly",
    menus: [
      { href: "/about", label: "about" },
      { href: "/contact", label: "contact" },
      { href: "/faq", label: "faq" },
      { href: "#", label: "bonus_program" },
      { href: "/blog", label: "blog" },
    ],
  },
  {
    id: "2",
    title: "Opportunities",
    menus: [
      { href: "#", label: "group_booking" },
      { href: "#", label: "business_class" },
      { href: "#", label: "business_travel" },
      { href: "#", label: "smart_connections" },
      { href: "#", label: "non_iata_agencies_affiliation" },
    ],
  },
  {
    id: "3",
    title: "Regulations",
    menus: [
      { href: "/politica-de-confidentialitate", label: "privacy_policy" },
      { href: "/conditii-de-asigurarea-bagajelor", label: "luggage_insurance_terms" },
      { href: "/regulamentul-iata", label: "iata_regulations" },
      { href: "/termeni-si-conditii", label: "terms_and_conditions" },
    ],
  },
  {
    id: "4",
    title: "Discover",
    menus: [
      { href: "/all-countries", label: "countries" },
      { href: "/all-cities", label: "cities" },
      { href: "/air-companies", label: "airlines" },
      { href: "https://t.me/hiflytravel", label: "hotels" },
      { href: "https://t.me/hiflytravel", label: "tourism" },
    ],
  },
];

const socialLinks = [
  { href: "https://www.facebook.com/share/1B3RGZyR8S/?mibextid=wwXIfr", icon: "fab fa-facebook-f" },
  { href: "https://www.instagram.com/hifly.md/profilecard/?igsh=NHR6NXhzd2JmaXFs", icon: "fab fa-instagram" },
  { href: "https://maps.app.goo.gl/D37oNWrZEmf21QG7A", icon: "fab fa-google" },
  { href: "https://youtube.com/@hi-flytravel?si=rPfUtaWBfFGrGjLz", icon: "fab fa-youtube" },
  { href: "https://www.tiktok.com/@hifly.md?_t=8sM3hY7S328&_r=1", icon: "fab fa-tiktok" },
];

const paymentMethods = [
  { icon: "fab fa-cc-mastercard" },
  { icon: "fab fa-cc-visa" },
  { icon: "fab fa-cc-paypal" },
  { icon: "fab fa-cc-amex" },
];

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-white pt-16 pb-8 px-4 shadow-[0_-15px_15px_-15px_rgba(0,0,0,0.1)]">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          {/* Logo and Contact Section */}
          <div className="md:col-span-2">
            <img src={logo} className="mb-7" />
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <Mail className="w-5 h-5 text-blue-900" />
                <a href="mailto:info@hifly.md" className="text-gray-600">info@hifly.md</a>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="w-5 h-5 text-blue-900" />
                <a href="tel:+37322000111" className="text-gray-600">+373 68 707 072 </a>
              </div>
            </div>
            <div className="mt-6">
              <h3 className="text-[#0063a7] font-medium mb-4">{t('footer.find_us_here')}</h3>
              <div className="flex gap-3">
                {socialLinks.map((social, index) => (
                  <a
                    key={index}
                    href={social.href}
                    className="w-8 h-8 flex items-center justify-center bg-[#0063a7] text-white rounded-[20px]"
                  >
                    <i className={social.icon}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Menu Sections */}
          {widgetMenus.map((section) => (
            <div key={section.id} className="md:col-span-1">
              <h3 className="text-[#0063a7] font-semibold text-lg mb-4">{t(`footer.${section.title.toLowerCase()}`)}</h3>
              <ul className="space-y-3">
                {section.menus.map((item, index) => (
                  <li key={index}>
                    <a href={item.href} className="text-gray-600 hover:text-blue-900 text-sm">
                      {t(`footer.${item.label}`)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Footer Bottom */}
        <div className="mt-16 pt-8 border-t border-gray-200">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-600 text-sm">
              © 2024 HI FLY SRL {t('footer.all_rights_reserved')}
            </p>
            <div className="flex items-center gap-6">
              {paymentMethods.map((method, index) => (
                <i key={index} className={`${method.icon} text-[#0063a7] text-3xl`} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
