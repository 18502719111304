import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import SocialsList from "shared/SocialsList/SocialsList";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import '@fortawesome/fontawesome-free/css/all.min.css';

const PageContact: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="nc-PageContact overflow-hidden">
      <Helmet>
        <title>{t("pageContact.title")}</title>
      </Helmet>

      <div className=" max-w-7xl mx-auto py-20">
        <h2 className="text-center text-3xl md:text-5xl font-bold mb-10">
          {t("pageContact.header")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Contact Info */}
          <div className="bg-[#0063a7] text-white p-10 rounded-lg">
            <h3 className="text-2xl font-semibold mb-4">{t("pageContact.contactInfo")}</h3>
            <p className="mb-6">{t("pageContact.contactText")}</p>
            <ul className="space-y-6">
              <li className="flex items-center">
                <span className="flex items-center justify-center w-10 h-10 bg-white text-black border border-gray-300 rounded-full mr-4">
                  <i className="fas fa-map-marker-alt text-[#0063a7]"></i>
                </span>
                <p className="leading-6">{t("pageContact.address")}</p>
              </li>
              <li className="flex items-center">
                <span className="flex items-center justify-center w-10 h-10 bg-white text-black border border-gray-300 rounded-full mr-4">
                  <i className="fas fa-phone-alt text-[#0063a7]"></i>
                </span>
                <p className="leading-6">{t("pageContact.phone")}</p>
              </li>
              <li className="flex items-center">
                <span className="flex items-center justify-center w-10 h-10 bg-white text-black border border-gray-300 rounded-full mr-4">
                  <i className="fas fa-envelope text-[#0063a7]"></i>
                </span>
                <p className="leading-6">{t("pageContact.email")}</p>
              </li>
              <li className="flex items-center">
                <span className="flex items-center justify-center w-10 h-10 bg-white text-black border border-gray-300 rounded-full mr-4">
                  <i className="fas fa-globe text-[#0063a7]"></i>
                </span>
                <p className="leading-6">{t("pageContact.website")}</p>
              </li>
            </ul>
          </div>

          {/* Contact Form */}
          <div className="p-10 border rounded-lg">
            <h3 className="text-2xl font-semibold mb-6">{t("pageContact.suggestionHeader")}</h3>
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <Input
                    placeholder={t("pageContact.namePlaceholder")}
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <Input
                    placeholder={t("pageContact.emailPlaceholder")}
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded"
                  />
                </div>
              </div>
              <div>
                <Input
                  placeholder={t("pageContact.subjectPlaceholder")}
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <Textarea
                  placeholder={t("pageContact.messagePlaceholder")}
                  rows={6}
                  className="w-full px-4 py-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <ButtonPrimary className="bg-[#0063a7] w-full py-3">{t("pageContact.sendButton")}</ButtonPrimary>
              </div>
            </form>
          </div>
        </div>

        {/* Google Maps */}
        <div className="mt-20">
          <div className="w-full h-[500px]">
            <iframe
              className="w-full h-full rounded-[10px]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11869.279707804493!2d28.84174189729749!3d47.02794830880367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cbd3eaf1f627bb%3A0xb24dcd7d5e6db393!2sStr.%20Grădina%20Botanică%202A%2C%20Chișinău%2C%20Republica%20Moldova!5e0!3m2!1sen!2sus!4v1689750870267!5m2!1sen!2sus"
              allowFullScreen
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
