import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SearchStateType = {
    guestAdults: number | null;
    guestChildren: number | null;
    guestInfants: number | null;
    originLocation: any;
    destinationLocation: any;
    flightDateRange: any;
};

let initialState: SearchStateType = {
    guestAdults: null,
    guestChildren: null,
    guestInfants: null,
    originLocation: null,
    destinationLocation: null,
    flightDateRange: null,
};

const searchSlice = createSlice({
    name: 'searchStoreModule',
    initialState,
    reducers: {
        setGuestAdults(state, action: PayloadAction<number | null>) {
            state.guestAdults = action.payload;
        },
        setGuestChildren(state, action: PayloadAction<number | null>) {
            state.guestChildren = action.payload;
        },
        setGuestInfants(state, action: PayloadAction<number | null>) {
            state.guestInfants = action.payload;
        },
        setOriginLocation(state, action: PayloadAction<any>) {
            state.originLocation = action.payload;
        },
        setDestinationLocation(state, action: PayloadAction<any>) {
            state.destinationLocation = action.payload;
        },
        setFlightDateRange(
            state,
            action: PayloadAction<[Date | null, Date | null]>
        ) {
            state.flightDateRange = action.payload;
        },
        clearSearch(state) {
            Object.assign(state, initialState);
        },
    },
});

export const {
    setGuestAdults,
    setGuestChildren,
    setGuestInfants,
    setOriginLocation,
    setDestinationLocation,
    setFlightDateRange,
    clearSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
