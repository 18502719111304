import React, { FC } from "react";

const PagePolicy: FC = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <h3 className="text-left text-2xl font-bold mb-6">Politica de Confidențialitate</h3>
        <div className="text-left space-y-4">

          <h2 className="text-1xl font-semibold mt-6">1. Introducere </h2>
          <p>Bine ați venit pe site-ul HI-FLY, o platformă digitală dedicată vânzării de bilete de avion și pachetelor de servicii turistice. </p>
          <p>Ne angajăm să protejăm confidențialitatea și securitatea datelor personale ale tuturor vizitatorilor și utilizatorilor serviciilor noastre. Această Politică de Confidențialitate explică în detaliu cum colectăm, utilizăm, protejăm și, în anumite circumstanțe, divulgăm informațiile personale ale utilizatorilor noștri. Scopul acestui document este de a vă asigura că înțelegeți ce date personale colectăm despre dumneavoastră, motivul pentru care le colectăm și utilizăm, și cât timp le păstrăm. </p>
          <p>Importanța Protecției Datelor Personale: </p>
          <p>- Transparență: Asigurarea transparenței în modul în care sunt gestionate datele personale este esențială pentru construirea și menținerea încrederii. Vrem să vă asigurăm că datele dumneavoastră sunt tratate cu respect și cu grijă.</p>
          <p>- Control: Este important pentru noi să vă oferim control asupra propriilor date. Vă oferim acces la informații despre datele personale pe care le deținem despre dumneavoastră și vă oferim posibilitatea de a solicita actualizarea sau ștergerea acestora. </p>
          <p>- Securitate: Ne angajăm să protejăm datele personale împotriva accesului neautorizat, pierderii accidentale, divulgării sau distrugerii neautorizate. Utilizăm o varietate de măsuri de securitate pentru a proteja și menține securitatea și integritatea datelor dumneavoastră personale. </p>
          <p>Această Politică de Confidențialitate se aplică tuturor serviciilor oferite de HI-FLY, inclusiv, dar fără a se limita la, site-ul web, serviciile online, și interacțiunile dumneavoastră directe cu noi. Vă invităm să citiți acest document cu atenție pentru a înțelege practicile noastre în materie de confidențialitate și modul în care vă afectează. </p>
          <p>Prin utilizarea serviciilor HI-FLY, vă exprimați acordul cu termenii descriși în această Politică de Confidențialitate. Dacă aveți întrebări sau nelămuriri legate de această politică, vă încurajăm să ne contactați prin canalele prevăzute în secțiunea de contact a acestui document. </p>
          <p>Noțiuni Definite: </p>
          <p>Pentru scopurile acestei Politici de Confidențialitate, următorii termeni sunt definiți astfel: </p>
          <p>- Date Personale: Orice informație care se referă la o persoană fizică identificată sau identificabilă („subiectul datelor”); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un identificator, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente specifice, proprii identității fizice, fiziologice, genetice, psihice, economice, culturale sau sociale ale acelei persoane fizice. </p>
          <p>- Prelucrarea: Orice operațiune sau set de operațiuni efectuate asupra datelor personale sau asupra seturilor de date personale, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziție în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea. </p>
          <p>- Consimțământ: Acordul liber, informat, specific și neechivoc al subiectului datelor, prin care acesta, printr-o declarație sau printr-o acțiune afirmativă clară, acceptă prelucrarea datelor personale care îl privesc. </p>
          <p>- Terț: O persoană fizică sau juridică, o autoritate publică, o agenție sau un organism, altul decât subiectul datelor, controlorul, procesatorul și persoanele care, sub autoritatea directă a controlorului sau procesatorului, sunt autorizate să prelucreze datele personale. </p>

          <h2 className="text-1xl font-semibold mt-6">2. Ce Date Personale Colectăm </h2>
          <p>La HI-FLY, colectăm diverse tipuri de date personale pentru a oferi și îmbunătăți serviciile noastre. Înțelegerea tipurilor de date pe care le colectăm este esențială pentru a asigura o transparență completă și pentru a vă informa despre informațiile pe care le deținem despre dumneavoastră. </p>
          <p>Tipurile de Date Colectate Direct de la Utilizatori: </p>
          <p>- Date de Identificare și Contact: Acestea includ numele și prenumele, adresa de e-mail, numărul de telefon, adresa poștală și orice alte informații pe care ni le furnizați direct prin formularele de pe site, cum ar fi atunci când vă creați un cont, când efectuați o rezervare sau când vă înscrieți la newsletter-ul nostru. </p>
          <p>- Detalii de Plată și Tranzacții: Colectăm detalii financiare necesare pentru procesarea plăților atunci când achiziționați bilete de avion sau pachete turistice.  </p>
          <p>- Preferințe Personale și Feedback: Informații pe care ni le furnizați despre preferințele dumneavoastră, cum ar fi preferințe legate de zbor sau feedback-ul oferit despre serviciile noastre. </p>
          <p>Date Colectate Automat prin Utilizarea Serviciilor Noastre: </p>
          <p>- Date de Utilizare: Colectăm informații despre cum interacționați cu site-ul nostru, cum ar fi paginile vizitate, linkurile pe care faceți click, timpul petrecut pe anumite secțiuni și alte acțiuni similare. </p>
          <p>- Date Tehnice: Pentru a îmbunătăți performanța site-ului și a asigura o funcționare corectă, colectăm automat date tehnice despre dispozitivul dumneavoastră, cum ar fi adresa IP, tipul de browser, setările limbii, sistemul de operare, și informații despre rețeaua de internet. </p>
          <p>- Cookie-uri și Tehnologii de Urmărire: Utilizăm cookie-uri și alte tehnologii similare pentru a colecta informații despre activitatea dumneavoastră online și despre dispozitivele pe care le utilizați pentru a accesa serviciile noastre, pentru a personaliza experiența dumneavoastră și a oferi conținut adaptat intereselor dumneavoastră. </p>

          <h2 className="text-1xl font-semibold mt-6">3. Cum Utilizăm Datele Colectate </h2>
          <p>Scopurile Procesării Datelor Personale: </p>
          <p>- Pentru Furnizarea Serviciilor: Utilizăm datele personale pentru a gestiona rezervările, a emite biletele, a procesa plățile și a oferi serviciile solicitate de clienți. Aceasta include comunicarea cu dumneavoastră pentru confirmarea rezervărilor sau pentru a oferi informații importante legate de călătoriile dumneavoastră. </p>
          <p>- Pentru Îmbunătățirea Serviciilor: Datele colectate ne ajută să înțelegem cum utilizatorii interacționează cu site-ul nostru, ceea ce ne permite să optimizăm experiența online și să dezvoltăm funcții și servicii noi care să răspundă nevoilor și preferințelor clienților. </p>
          <p>- Pentru Marketing și Comunicări: Cu consimțământul dumneavoastră, putem utiliza datele personale pentru a vă trimite actualizări, oferte speciale și informații despre produsele și serviciile noastre prin e-mail, SMS sau alte canale de comunicare directă. </p>
          <p>- Pentru Analize și Rapoarte: Utilizăm datele pentru analize interne, studii de piață și elaborarea de rapoarte pentru a îmbunătăți deciziile de afaceri și strategiile de marketing. </p>
          <p>Baza Legală pentru Prelucrarea Datelor: </p>
          <p>- Executarea Contractului: Prelucrarea datelor personale este necesară pentru executarea contractului la care sunteți parte, cum ar fi pentru furnizarea serviciilor de călătorie pe care le-ați rezervat prin HI-FLY. </p>
          <p>- Consimțământul: În unele cazuri, ne bazăm pe consimțământul dumneavoastră expres, cum ar fi pentru trimiterea de materiale de marketing și comunicări. Puteți retrage acest consimțământ în orice moment. </p>
          <p>- Interesele Legitime: Utilizăm datele personale pentru a servi interesele noastre legitime, cum ar fi pentru a îmbunătăți serviciile și experiența utilizatorului pe site, atât timp cât aceste activități nu încalcă drepturile și libertățile dumneavoastră fundamentale. </p>
          <p>- Conformitatea cu Obligațiile Legale: În anumite cazuri, prelucrăm datele personale pentru a ne conforma obligațiilor legale, cum ar fi reglementările fiscale sau alte cerințe guvernamentale. </p>

          <h2 className="text-1xl font-semibold mt-6">4. Partajarea Datelor cu Terți </h2>
          <p>HI-FLY recunoaște importanța confidențialității datelor personale ale utilizatorilor săi și se angajează să protejeze aceste informații. Partajarea datelor cu terți este efectuată în strictă conformitate cu politica noastră de confidențialitate și legislația aplicabilă. Această secțiune detaliază cu cine și de ce s-ar putea împărtăși datele dumneavoastră personale. </p>
          <p>Categoriile de Destinatari Căror Le Pot Fi Dezvăluite Datele: </p>
          <p>- Furnizori de Servicii: Colaborăm cu diverse companii și contractori terți pentru a oferi servicii, inclusiv companii aeriene, hoteluri, agenții de turism și furnizori de tehnologie. Acești furnizori pot avea acces la datele personale necesare pentru a îndeplini serviciile contractate, dar sunt restricționați să folosească aceste date pentru orice alt scop. </p>
          <p>- Parteneri de Marketing: Cu consimțământul dumneavoastră, putem împărtăși datele dumneavoastră cu parteneri de marketing pentru a vă oferi promoții și oferte relevante. Această partajare va fi întotdeauna supusă opțiunilor dumneavoastră de consimțământ exprimat. </p>
          <p>- Autorități Reglementatoare și Juridice: Datele pot fi dezvăluite autorităților publice sau altor părți terțe, dacă este necesar pentru conformitatea cu obligațiile legale, pentru protejarea drepturilor sau proprietății HI-FLY, sau în cadrul procedurilor legale. </p>
          <p>Motivele Partajării Datelor: </p>
          <p>- Executarea Contractelor: Partajăm datele pentru a îndeplini contractul cu dumneavoastră, cum ar fi rezervarea unui zbor sau a unei camere de hotel, necesitând transmiterea datelor către companii aeriene și hoteluri. </p>
          <p>- Îmbunătățirea Serviciilor: Împărtășim informații cu furnizorii de servicii pentru a îmbunătăți calitatea și eficiența serviciilor oferite prin site-ul HI-FLY. </p>
          <p>- Marketing: Cu permisiunea dumneavoastră, folosim și împărtășim datele dumneavoastră pentru a vă oferi informații personalizate despre oferte și promoții care v-ar putea interesa. </p>
          <p>- Obligații Legale: În anumite circumstanțe, poate fi necesar să divulgăm datele pentru a respecta cerințele legale sau regulamentele aplicabile. </p>

          <h2 className="text-1xl font-semibold mt-6">5. Cum Protejăm Datele Personale</h2>
          <p>La HI-FLY, protecția datelor personale ale utilizatorilor noștri este de o importanță crucială. Implementăm măsuri riguroase de securitate pentru a proteja datele împotriva accesului neautorizat, alterării, divulgării sau distrugerii neautorizate. Această secțiune detaliază măsurile de securitate pe care le aplicăm și procedurile pe care le urmăm în cazul unei încălcări a securității datelor. </p>
          <p>Măsuri de Securitate Implementate pentru Protecția Datelor: </p>
          <p>- Securitatea Tehnică: Folosim tehnologii de criptare avansată pentru a proteja datele transmise între dispozitivele utilizatorilor și serverele noastre. De asemenea, aplicăm firewall-uri și sisteme de detectare a intruziunilor pentru a preveni accesul neautorizat la rețelele noastre. </p>
          <p>- Controlul Accesului: Accesul la datele personale este strict limitat la angajații și colaboratorii care au nevoie de aceste informații pentru a îndeplini responsabilitățile lor profesionale. Toți angajații și colaboratorii sunt instruiți cu privire la importanța confidențialității datelor și sunt obligați să respecte politicile noastre stricte de confidențialitate. </p>
          <p>- Securitatea Fizică: Măsurile de securitate fizică, inclusiv sisteme de securitate la sediul companiei și măsuri de control al accesului, sunt implementate pentru a proteja locațiile unde se stochează datele personale. </p>
          <p>- Audituri de Securitate: Conducem audituri regulate de securitate și evaluări ale vulnerabilităților pentru a identifica și remedia potențialele riscuri de securitate. </p>
          <p>Proceduri în Caz de Încălcarea Securității Datelor: </p>
          <p>- Răspuns Imediat: În cazul detectării unei încălcări a securității datelor, echipa noastră de securitate IT intervine imediat pentru a limita impactul și pentru a identifica cauza problemei. </p>
          <p>- Notificare: Dacă încălcarea securității datelor afectează în mod semnificativ datele personale ale utilizatorilor sau prezintă un risc mare pentru drepturile și libertățile acestora, vom notifica persoanele afectate și autoritățile de reglementare competente, conform legislației în vigoare, în cel mult 72 de ore de la descoperirea încălcării. </p>
          <p>- Investigație și Remediere: Efectuăm investigații detaliate pentru a determina cauzele încălcării și implementăm măsuri de remediere pentru a preveni incidente similare în viitor. </p>
          <p>- Comunicare Transparentă: Ne angajăm să comunicăm deschis și sincer cu utilizatorii noștri în legătură cu orice încălcare a securității datelor, explicând natura încălcării, efectele posibile și măsurile luate pentru adresarea situației. </p>
          <p>Prin aceste măsuri de securitate și proceduri de răspuns, HI-FLY își demonstrează angajamentul de a proteja datele personale ale utilizatorilor și de a menține încrederea acestora în serviciile noastre. Ne asumăm responsabilitatea de a gestiona în mod responsabil și sigur informațiile pe care ni le încredințați.  </p>

          <h2 className="text-1xl font-semibold mt-6">6. Drepturile Utilizatorilor </h2>
          <p>HI-FLY respectă drepturile utilizatorilor de a controla datele lor personale. Conform legislației privind protecția datelor, utilizatorii au diverse drepturi legate de modul în care informațiile lor personale sunt prelucrate. Această secțiune explică aceste drepturi și modul în care pot fi exercitate. </p>
          <p>Lista Drepturilor Utilizatorilor în Legătură cu Datele Lor Personale: </p>
          <p>1. Dreptul la acces: utilizatorii pot solicita o copie a datelor personale pe care le deținem despre ei și pot verifica dacă le prelucrăm în mod legal.</p>
          <p>2. Dreptul la rectificare: utilizatorii au dreptul de a solicita corectarea datelor personale incorecte sau incomplete. </p>
          <p>3. Dreptul la ștergere: cunoscut și sub numele de „dreptul de a fi uitat”, acest drept permite utilizatorilor să solicite ștergerea sau eliminarea datelor personale atunci când nu există motive legitime pentru prelucrarea lor continuă. </p>
          <p>4. Dreptul la restricționarea prelucrării: utilizatorii pot solicita suspendarea prelucrării datelor personale în anumite circumstanțe, cum ar fi atunci când doresc să ne verificăm exactitatea datelor sau motivele prelucrării. </p>
          <p>5. Dreptul la portabilitatea datelor: utilizatorii au dreptul de a obține și de a reutiliza datele lor personale pentru propriile scopuri în diferite servicii, într-un format structurat, utilizat în mod obișnuit și lizibil automat. </p>
          <p>6. Dreptul la obiecție: utilizatorii pot obiecta împotriva prelucrării datelor lor personale când prelucrarea se bazează pe un interes legitim (inclusiv profilarea) sau când este efectuată în scopuri de marketing direct. </p>
          <p>7. Dreptul de a nu fi supus unei decizii bazate exclusiv pe prelucrarea automată: inclusiv profilarea, care produce efecte juridice sau îi afectează în mod similar în mod semnificativ. </p>
          <p>Cum Pot Utilizatorii Să Își Exercite Aceste Drepturi: </p>
          <p>- Contact Direct: Utilizatorii pot exercita oricare dintre aceste drepturi trimițând o cerere scrisă la adresa de email info@hi-fly.md sau prin poștă la adresa juridică a HI-FLY. Asigurați-vă că includeți suficiente informații pentru a vă identifica și a procesa cererea. </p>
          <p>- Formulare de Cerere: Pentru a facilita procesarea cererilor, HI-FLY poate oferi formulare standard pe site-ul său web. Utilizatorii sunt încurajați să utilizeze aceste formulare pentru a asigura că cererile sunt cât mai clare și complete posibil. </p>
          <p>- Asistență pentru Utilizatori: Dacă aveți întrebări despre cum să exercitați aceste drepturi sau aveți nevoie de asistență legată de procesul de cerere, vă rugăm să contactați serviciul nostru de suport pentru clienți. </p>

          <h2 className="text-1xl font-semibold mt-6">7. Utilizarea Cookie-urilor și Tehnologiilor Similare </h2>
          <p>La HI-FLY, utilizăm cookie-uri și tehnologii similare pentru a îmbunătăți experiența utilizatorilor pe site-ul nostru, a oferi funcționalități avansate și a analiza traficul și performanța site-ului. Această secțiune explică modul în care folosim aceste tehnologii și opțiunile pe care le aveți la dispoziție pentru a gestiona preferințele. </p>
          <p>Explicarea Modului în Care Site-ul Utilizează Cookie-uri: </p>
          <p>- Cookie-uri de Funcționalitate: Aceste cookie-uri sunt esențiale pentru funcționarea corectă a site-ului nostru, permitându-vă să navigați și să utilizați funcții precum accesul la zonele sigure ale site-ului. Fără aceste cookie-uri, servicii cum ar fi cumpărăturile sau facturarea electronică nu pot fi oferite. </p>
          <p>- Cookie-uri de Performanță și Analiză: Folosim aceste cookie-uri pentru a colecta informații despre modul în care vizitatorii utilizează site-ul nostru, de exemplu, care pagini sunt vizitate cel mai des și dacă primesc mesaje de eroare de pe pagini. Aceste cookie-uri nu colectează informații care identifică direct un vizitator, ci sunt agregate și, prin urmare, anonime. Sunt folosite doar pentru a îmbunătăți modul în care funcționează un site. </p>
          <p>- Cookie-uri de Marketing și Publicitate: Aceste cookie-uri sunt folosite pentru a livra anunțuri relevante pentru interesele dumneavoastră. Ele sunt, de asemenea, folosite pentru a limita numărul de ori când vedeți o reclamă și pentru a ajuta la măsurarea eficacității campaniilor publicitare. </p>
          <p>Opțiuni Disponibile pentru Gestionarea Preferințelor: </p>
          <p>- Setările Browserului: Puteți alege să acceptați sau să refuzați cookie-urile modificând setările browserului dumneavoastră. Majoritatea browserelor oferă instrucțiuni despre cum să modificați setările de cookie-uri în meniul 'Ajutor' al browserului. Totuși, dacă alegeți să dezactivați cookie-urile, este posibil ca unele funcționalități interactive ale site-ului nostru să nu mai fie disponibile. </p>
          <p>- Panouri de Control al Consimțământului Cookie: Pe site-ul nostru, oferim un panou de control al consimțământului cookie unde puteți gestiona preferințele specifice pentru cookie-urile utilizate. Acest panou vă permite să vă personalizați experiența pe site-ul nostru în funcție de preferințele dvs. în materie de urmărire. </p>
          <p>- Instrumente de Terțe Părți: De asemenea, puteți utiliza instrumente oferite de terțe părți, cum ar fi TrustArc sau Cookie Consent Tool pentru a gestiona consimțământul pentru cookie-uri. </p>

          <h2 className="text-1xl font-semibold mt-6">8. Modificări ale Politicii de Confidențialitate </h2>
          <p>HI-FLY înțelege că transparența și comunicarea deschisă sunt esențiale pentru menținerea încrederii utilizatorilor noștri. Din acest motiv, ne rezervăm dreptul de a actualiza și modifica această Politică de Confidențialitate pentru a reflecta schimbările în practicile noastre de prelucrare a datelor sau schimbările în legislația aplicabilă. Această secțiune descrie procesul nostru de actualizare a politicii și modul în care vă informăm despre aceste modificări. </p>
          <p>Procesul de Actualizare a Politicii: </p>
          <p>- Evaluare Continuă: Revizuim periodic Politica de Confidențialitate pentru a ne asigura că rămâne actuală și că reflectă corect modul în care prelucrăm datele personale. Aceasta include revizuirea practicilor noastre de securitate și adaptarea la noile cerințe legale sau tehnologice. </p>
          <p>- Implicarea Echipei: Actualizările sunt realizate în colaborare cu departamentele relevante din cadrul HI-FLY, inclusiv legal, IT, și securitate, pentru a asigura că toate aspectele prelucrării datelor sunt adecvat adresate. </p>
          <p>Cum Sunt Informați Utilizatorii Despre Modificări: </p>
          <p>- Notificare Activă: În cazul unor modificări semnificative ale Politicii de Confidențialitate, vom informa utilizatorii prin mijloace adecvate, cum ar fi prin email sau prin anunțuri vizibile plasate pe site-ul nostru. Acest lucru asigură că utilizatorii sunt conștienți de schimbările care ar putea afecta modul în care sunt prelucrate datele lor personale. </p>
          <p>- Accesibilitatea Politicii: Noua versiune a Politicii de Confidențialitate va fi întotdeauna accesibilă pe site-ul nostru, cu data ultimei actualizări clar afișată, astfel încât utilizatorii să poată verifica ușor când a fost revizuită politica ultima dată. </p>
          <p>- Opțiunea de a opta: Dacă modificările aduse politicii afectează consimțământul utilizatorilor sau modul în care datele personale ale utilizatorilor sunt tratate, utilizatorii vor avea opțiunea de a retrage consimțământul sau de a opta pentru configurări diferite în ceea ce privește prelucrarea datelor lor personale. </p>
          <p>Ne angajăm să gestionăm orice modificare a Politicii de Confidențialitate într-un mod deschis și transparent, asigurându-ne că utilizatorii noștri sunt bine informați și că au control asupra datelor lor personale. Dacă aveți întrebări sau nelămuriri legate de orice modificare, vă încurajăm să contactați departamentul nostru de confidențialitate.  </p>

          <h2 className="text-1xl font-semibold mt-6">9. Informații de Contact  </h2>
          <p>La HI-FLY, comunicarea eficientă și transparentă cu utilizatorii noștri este esențială. Dacă aveți întrebări, nelămuriri sau reclamații legate de modul în care sunt prelucrate datele dumneavoastră personale, sau dacă doriți să exercitați drepturile dumneavoastră privind protecția datelor, vă încurajăm să contactați responsabilul nostru cu protecția datelor folosind informațiile de mai jos. </p>
          <p>- E-mail: Pentru orice întrebări legate de datele personale, puteți trimite un e-mail direct la adresa noastră dedicată: privacy@hi-fly.md. Aceasta este cea mai rapidă metodă pentru a obține răspunsuri la întrebările legate de confidențialitate. </p>
          <p>- Telefon: Dacă preferați să discutați personal, puteți apela la numărul nostru de telefon dedicat problemei protecției datelor: +373 68 70 70 72. Echipa noastră este disponibilă în timpul orelor de lucru obișnuite pentru a vă asista cu orice nelămuriri. </p>
          <p>- Poștă: Alternativ, puteți trimite o scrisoare la adresa noastră poștală: Str. Grădina Botanică, Nr. 2A, mun. Chișinău, MD-2002, Republica Moldova. Aceasta poate fi folosită pentru documentația oficială sau pentru cereri detaliate. </p>
          <p>La HI-FLY, ne angajăm să protejăm și să respectăm confidențialitatea datelor dumneavoastră personale și suntem aici pentru a asigura că orice întrebare sau preocupare este abordată în mod corespunzător. Vă mulțumim pentru încrederea acordată și vă asigurăm că ne străduim să gestionăm datele dumneavoastră personale. </p>
        </div>
      </div>
    </div>
  );
};

export default PagePolicy;
