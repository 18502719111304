import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQ_ITEMS = [
  {
    questionKey: "faq.question-1",
    answerKey: "faq.answer-1",
  },
  {
    questionKey: "faq.question-2",
    answerKey: "faq.answer-2",
  },
  {
    questionKey: "faq.question-3",
    answerKey: "faq.answer-3",
  },
  {
    questionKey: "faq.question-4",
    answerKey: "faq.answer-4",
  },
  {
    questionKey: "faq.question-5",
    answerKey: "faq.answer-5",
  },
  {
    questionKey: "faq.question-6",
    answerKey: "faq.answer-6",
  },
];

function SectionFAQ() {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="nc-SectionFAQ mt-28">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold">{t("faq.title")}</h2>
      <div className="mt-8 space-y-4">
        {FAQ_ITEMS.map((item, index) => (
          <div
            key={index}
            className="bg-white pt-3 pb-3 border-b border-gray-300 cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">{t(item.questionKey)}</h3>
              <span
                className={`flex items-center justify-center w-8 h-8 border bg-gray-100 transition-transform rounded-[5px] ${
                    openIndex === index ? "rotate-90" : ""
                  }`}
              >
                ▶
              </span>
            </div>
            {openIndex === index && (
              <p className="mt-3 text-gray-600">{t(item.answerKey)}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionFAQ;
