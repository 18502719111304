import React, { FC } from "react";
import { useTranslation } from "react-i18next"; // Import pentru traducere
import rightImgPng from "images/our-features.svg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
                                                           className = "lg:py-20 mt-10",
                                                           rightImg = rightImgPng,
                                                           type = "type1",
                                                         }) => {
  const { t } = useTranslation(); // Obținem funcția de traducere

  return (
      <div
          className={`nc-SectionOurFeatures relative flex flex-col items-center ${
              type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
          } ${className}`}
          data-nc-id="SectionOurFeatures"
      >
        <div className="flex-grow">
          <NcImage src={rightImg} />
        </div>
        <div
            className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
                type === "type1" ? "lg:pl-16" : "lg:pr-16"
            }`}
        >
          <span className="uppercase text-sm text-gray-400 tracking-widest">
            {t("features.beneficii")} {/* Traducere pentru "BENEFICII" */}
          </span>
          <h2 className="font-semibold text-4xl mt-5">{t("features.deCeSaNeAlegi")}</h2> {/* Traducere pentru "De ce să ne alegi?" */}

          <ul className="space-y-10 mt-16">
            <li className="space-y-4">
              <Badge name={t("features.preturiAccesibile")} /> {/* Traducere pentru "Prețuri accesibile" */}
              <span className="block text-xl font-semibold">
                {t("features.gasesteCeleMaiBuneOferte")}
              </span> {/* Traducere pentru "Găsește cele mai bune oferte pentru biletele tale" */}
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {t("features.comparamPreturile")}
              </span> {/* Traducere pentru "Comparăm prețurile de la companii aeriene din întreaga lume pentru a-ți oferi cele mai mici tarife." */}
            </li>
            <li className="space-y-4">
              <Badge color="red" name={t("features.asistenta247")} /> {/* Traducere pentru "Asistență 24/7" */}
              <span className="block text-xl font-semibold">
                {t("features.suportClientiDedicate")}
              </span> {/* Traducere pentru "Suport clienți dedicat, non-stop" */}
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {t("features.echipaDisponibila247")}
              </span> {/* Traducere pentru "Echipa noastră este disponibilă 24/7 pentru a răspunde la orice întrebare sau problemă legată de rezervările tale." */}
            </li>
            <li className="space-y-4">
              <Badge color="green" name={t("features.recompense")} /> {/* Traducere pentru "Recompense" */}
              <span className="block text-xl font-semibold">
                {t("features.castigaPuncteFidelitate")}
              </span> {/* Traducere pentru "Câștigă puncte de fidelitate la fiecare rezervare" */}
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {t("features.puncteBonusPentruAchizitii")}
              </span> {/* Traducere pentru "Fă-ți cont la noi și beneficiază de puncte bonus pentru fiecare achiziție, pe care le poți folosi la zborurile viitoare." */}
            </li>
          </ul>
        </div>
      </div>
  );
};

export default SectionOurFeatures;
