import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const AllCities: FC = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const alphabet = ["TOATE", "A", "B", "C", "D", "E", "G", "H", "I", "K", "L", "M", "N", "O", "P", "R", "S", "T", "V", "Z"];
    const cities = [
        { name: "Abu Dhabi", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuS-qaUDTM78HAUj0jf-UPP31Dr2B4q0Sylw&s" },
        { name: "Alicante", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPqch1sQTxPEV2_XycprnBlYVg1P4lCSBLrA&s" },
        { name: "Amsterdam", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYF7_SiHGnlBl_cBSJc8QJNnXandR8WWgJFQ&s" },
        { name: "Ankara", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfzdh2W6Hf7rnK-T8LR2DXGLLnAOgPeogkbQ&s" },
        { name: "Antalya", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcI4Fqz6UTjU9-_3-OuyqXtf74JRMmWsTvGw&s" },
        { name: "Anvers", offers: "Vezi oferta", logo: "https://media.routard.com/image/32/7/fb-anvers.1526327.jpg" },
        { name: "Atena", offers: "Vezi oferta", logo: "https://www.sunrise-travel.eu/~uploads/images/Acropolis-1.jpeg" },
        { name: "Bacău", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlCOIaeQM2O0YOA0MawWv85fG31NLpOrRVsQ&s" },
        { name: "Baia Mare", offers: "Vezi oferta", logo: "https://basilica.ro/wp-content/uploads/2021/05/98034741_3338034739563951_7276932814388330496_o.jpg" },
        { name: "Baku", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmabBtPBxX2C4U0cE8Q2f6tjlB5wNrEfB18g&s" },
        { name: "Baltimore", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKLuLSXwwbN3Oxkfc5aF8f5qRtskKk68oXsg&s" },
        { name: "Barcelona", offers: "Vezi oferta", logo: "https://d3dqioy2sca31t.cloudfront.net/Projects/cms/production/000/020/484/medium/d0531471711b367b94abfd4dbc29e6ae/spain-barcelona-sagrada-familia-080416-az.jpg" },
        { name: "Batumi", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpT5HtXmBlzA9pP81qlH0TsN9yi3pu8Wb1qw&s" },
        { name: "Beijing", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1MR9G018ZPbXGtx-clj6IIkSEw5gVy1bpAg&s" },
        { name: "Belgrad", offers: "Vezi oferta", logo: "https://avraamtravel.ro/wp-content/uploads/2023/09/Cetatea-Belgrad.jpg" },
        { name: "Bergen", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-9Fl7k7ny6V_TO6eqBA78Zq4CffTEO7a3zg&s" },
        { name: "Berlin", offers: "Vezi oferta", logo: "https://www.civitatis.com/f/alemania/berlin/berlin-m.jpg" },
        { name: "Bologna", offers: "Vezi oferta", logo: "https://www.travelonatimebudget.co.uk/wp-content/uploads/2023/02/The-Two-Towers-Bologna.jpg" },
        { name: "Bordeaux", offers: "Vezi oferta", logo: "https://images.ctfassets.net/zmjc9gr9hbbf/5IsCRlzp1wa9lz8NJIEp15/1094590f684aee64daadc7775e07227e/bordeaux_shutterstockF240209116.jpg?w=1980" },
        { name: "Bremen", offers: "Vezi oferta", logo: "https://thehappyjetlagger.com/wp-content/uploads/2022/03/bremen-sehenswuerdigkeiten.jpg" },
        { name: "Bruxelles", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUxbscA158mNoQvgia_Jorh4aD0AOuV58CAg&s" },
        { name: "București", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ17tO4BWt8_rcEEfpAwQJ_VfPepG_GBSRwXg&s" },
        { name: "Budapesta", offers: "Vezi oferta", logo: "https://images.ttm.network/qtwYz5J7QtEhWjoz5rLrMjRvG7A=/1280x960/4bae607fbe914da58b3a3629ce8ca8b7%2Fchi%C8%99in%C4%83u-budapesta-1280x960.jpg" },
        { name: "Burgas", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlptfoyN-aMKIpRA59Wbx49tFSBMxqGC5Vmw&s" },
        { name: "Calgary", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG9PwYwzdRjkwaAs0_KsjTVryYIxj6-UQYcA&s" },
        { name: "Chișinău", offers: "Vezi oferta", logo: "https://visit.chisinau.md/wp-content/uploads/2021/09/Catedrala-si-Clopotnita-1-e1638435096460.jpg" },
        { name: "Cluj-Napoca", offers: "Vezi oferta", logo: "https://tripjive.com/wp-content/uploads/2024/05/Cluj-Napoca-Itinerary-5-Days.jpg" },
        { name: "Copenhaga", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3frvy3iKjfKVYtnTnKn4MmwYIyoVFKypj4g&s" },
        { name: "Dubai", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz6X0s7ydkQPmeHia4MWhAqLp1QwprsRptxw&s" },
        { name: "Dublin", offers: "Vezi oferta", logo: "https://bucket-files.city-sightseeing.com/blog/2023/02/cityview-dublin-1024x683.jpg" },
        { name: "Edinburgh", offers: "Vezi oferta", logo: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/ff/32/2a/edinburgh-castle.jpg?w=1200&h=1200&s=1" },
        { name: "Geneva", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWYyN8X8GQCTernSdbKPvqJte3tXE2L99wsA&s" },
        { name: "Hamburg", offers: "Vezi oferta", logo: "https://digital.ihg.com/is/image/ihg/768x576-Hamburg-overviewNEW" },
        { name: "Helsinki", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB70cttumQGx92OAQOO5xSHTPw_5hdlNHdHA&s" },
        { name: "Istanbul", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx6NvLowR5L-A4g2T1qHwW2EX5-KV2Y5iY_A&s" },
        { name: "Kuala Lumpur", offers: "Vezi oferta", logo: "https://i.natgeofe.com/n/1690f081-f44f-4fbe-a1b6-9123819b737c/petronas-kualalumpur-malaysia_3x4.jpg" },
        { name: "Lisabona", offers: "Vezi oferta", logo: "https://images.ttm.network/yo05908_QXJZZKXkFU4BljCkBwY=/800x450/filters:format(jpg)/0a27a32a35db4aa28485d83e3cb1676e/descopera-lisabona-7-destinatii-de-neuitat-800-x-450.jpg" },
        { name: "Londra", offers: "Vezi oferta", logo: "https://londontickets.tours/wp-content/uploads/2022/12/london-tower-bridge.jpg" },
        { name: "Luxemburg", offers: "Vezi oferta", logo: "https://cdn.getyourguide.com/img/tour/8cf7b09a8251dffe.jpeg/130.webp" },
        { name: "Madrid", offers: "Vezi oferta", logo: "https://media.cntraveler.com/photos/6717c243f1348ea41c7b6319/16:9/w_2560%2Cc_limit/madrid-GettyImages-1837573680.jpg" },
        { name: "München", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2XhsKdv6PjPTDKqjCe5GFnG8-MUIjP_MnaA&s" },
        { name: "Nairobi", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnat7okLIs4lWoh-oy2XCHU2mpNoTD1lgyeQ&s" },
        { name: "Oslo", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB6ieDO8SI53ChnNvdcBscMWDnLjbHGQOqgg&s" },
        { name: "Paris", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnbo-vIfVjh-kezUFRNpSwrrkNFikeVEF1WQ&s" },
        { name: "Praga", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpMSouamBhVLzqIF_N9otQxCWfkkV3cWpXZQ&s" },
        { name: "Riga", offers: "Vezi oferta", logo: "https://images.ctfassets.net/nwbqij9m1jag/4dxzxt15VKKWwiAqMkACyU/90163ed13d944d650722683f7f308bdf/city-hall-square-old-town-riga.jpg" },
        { name: "Roma", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi4S-xLqS6-JY3RKY0DyXPYXNiksz0fppfvg&s" },
        { name: "Sofia", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv2Mj2yBwZYI8yG5hgfLHBNyCRT6ip9NZNtw&s" },
        { name: "Stockholm", offers: "Vezi oferta", logo: "https://hips.hearstapps.com/hmg-prod/images/stockholm-sweden-scenic-summer-sunset-view-with-royalty-free-image-1713459498.jpg?crop=0.668xw:1.00xh;0.159xw,0&resize=1120:*" },
        { name: "Tokyo", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp2_g_kfGnkBDIRyMrXys9C8h7bp1TYpEAnQ&s" },
        { name: "Viena", offers: "Vezi oferta", logo: "https://images.ttm.network/oowhL6FZqaCIWFDm0-QpKVzTFlQ=/1280x1280/b9938c36043145e79ac9fe90ebdd20c1%2Fviena-o-destina%C8%9Bie-de-vis-1280x1280.jpg" },
        { name: "Vilnius", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoq-hJHACWhYzHtj3Sm-mjUmUn6dO9zbMvhQ&s" },
        { name: "Zagreb", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP-fT4wD6qJzmgqC0U9Ei0bxBGIp4MoN8MVw&s" },
        { name: "Zakynthos", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUO_KRNWPPDp15r85Rug23DSxE1yuqSi-QnQ&s" },
        { name: "Zanzibar", offers: "Vezi oferta", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ936ib06dnVcy2y-V6BV6xrffUlmpq2Za6DA&s" },
        { name: "Zurich", offers: "Vezi oferta", logo: "https://img.static-kl.com/images/media/506F5E1C-28C1-4555-A238CB9D4AD6039E" }
    ];


    const handleLetterClick = (letter: string) => {
        if (letter === "TOATE") {
            setSearch("");
        } else {
            setSearch(letter);
        }
    };

    const filteredCity = cities.filter(city =>
        city.name.toLowerCase().startsWith(search.toLowerCase())
    );

    const handleCardClick = (cityName: string) => {
        window.location.assign("https://www.google.com");
    };

    return (
        <div className="bg-gray-100 min-h-screen py-10">
            <div className="container sm:max-w-[75%] mx-auto px-4 md:px-6 py-8">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                {t("allCities.title")}
                </h1>
                <p className="text-lg sm:text-2xl font-medium text-gray-600 mb-6">
                {t("allCities.description")}
                </p>
                <input
                    type="text"
                    placeholder={t('allCities.search')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-full p-4 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <div className="flex flex-wrap gap-2 mb-6">
                    {alphabet.map((letter, index) => (
                        <button
                            key={index}
                            onClick={() => handleLetterClick(letter)}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none"
                        >
                            {letter}
                        </button>
                    ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredCity.map((city, index) => (
                        <div
                            key={index}
                            className="flex items-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm transition-shadow cursor-pointer hover:shadow-md"
                            onClick={() => handleCardClick(city.name)}
                        >
                            <img
                                src={city.logo}
                                alt={city.name}
                                className="w-24 h-24 object-cover rounded-md"
                            />
                            <div className="ml-4">
                                <h3 className="text-xl font-medium text-gray-800">{city.name}</h3>
                                <p className="text-base text-gray-500">{city.offers}</p>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default AllCities;
