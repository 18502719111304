// src/components/FilterSidebar.tsx
import React, { useState } from "react";
import PriceFilter from "./PriceFilter";
import EscaleDuration from "./EscaleDuration";
import TimeSelector from "./TimeSelector";

const FilterSidebar: React.FC = () => {
    const [priceRange, setPriceRange] = useState([75, 1625]);
    const [duration, setDuration] = useState<number[]>([1, 24]);
    const [showAllAirlines, setShowAllAirlines] = useState(false);
    const [timeValuesDeparture, setTimeValuesDeparture] = useState([325, 1360]); // 05:25 - 22:40 în minute
  const [timeValuesLanding, setTimeValuesLanding] = useState([70, 1425]);

    const handlePriceChange = (values: number[]) => {
        setPriceRange(values);
    };

    const handlePriceReset = () => {
        setPriceRange([75, 1625]);
    };

    const handleChange = (values: number[]) => {
        setDuration(values);
    };

    const handleTimeChangeDeparture = (newValues: number[]) => {
        setTimeValuesDeparture(newValues);
      };
    
      const handleTimeChangeLanding = (newValues: number[]) => {
        setTimeValuesLanding(newValues);
      };

    const handleReset = () => {
        setDuration([1, 24]);
        setTimeValuesDeparture([325, 1360]); // Resetare la intervalul inițial
    setTimeValuesLanding([70, 1425]);
    };

    const minDuration = 0;   // Example: 0 minutes
  const maxDuration = 1440; 


    return (
        <div className="lg:col-span-1 p-6 space-y-6">
            <h3 className="text-xl font-semibold mb-4">Filtrează zborurile</h3>

            <PriceFilter
                minPrice={75}
                maxPrice={1625}
                values={priceRange}
                onChange={handlePriceChange}
                onReset={handlePriceReset}
            />

            {/* Filtru Escale */}
            <div className="space-y-2">
                <label className="block text-l font-bold text-neutral-700 dark:text-neutral-300">Escale</label>
                <div className="space-y-2">
                    <label htmlFor="nonstop" className="flex items-center text-sm">
                        <input type="checkbox" id="nonstop" className="mr-2" />
                        <span>Fără escală</span>
                    </label>
                    <label htmlFor="oneStop" className="flex items-center text-sm">
                        <input type="checkbox" id="oneStop" className="mr-2" />
                        <span>Maximum 1 escală</span>
                    </label>
                    <label htmlFor="twoStops" className="flex items-center text-sm">
                        <input type="checkbox" id="twoStops" className="mr-2" />
                        <span>Maximum 2 escale</span>
                    </label>
                    <label htmlFor="threeStops" className="flex items-center text-sm">
                        <input type="checkbox" id="threeStops" className="mr-2" />
                        <span>Maximum 3 escale</span>
                    </label>
                    <label htmlFor="threeStops" className="flex items-center text-sm">
                        <input type="checkbox" id="threeStops" className="mr-2" />
                        <span>Exclude escalele de noapte</span>
                    </label>
                    <label htmlFor="threeStops" className="flex items-center text-sm">
                        <input type="checkbox" id="threeStops" className="mr-2" />
                        <span>Maximum 3 escale</span>
                    </label>
                </div>
            </div>

            <EscaleDuration
                minDuration={1}
                maxDuration={24}
                values={duration}
                onChange={handleChange}
                onReset={handleReset}
            />

            {/* Filtru Companii Aeriene */}
            <div className="space-y-2">
                <h4 className="block text-l font-bold text-neutral-700 dark:text-neutral-300">Companii Aeriene</h4>
                <div className="space-y-2">
                    <div className="flex items-center justify-between text-sm">
                        <span>Companii low-cost</span>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between text-sm">
                        <span>Companii de linie</span>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between text-sm">
                        <span>Conexiuni inteligente</span>
                        <input type="checkbox" className="mr-2" />
                    </div>
                </div>

                {/* Listă companii aeriene cu logo și checkbox */}
                <div className="space-y-2">
                    {/* List of airlines */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/5F.avif" alt="FlyOne Logo" className="w-6 h-6 rounded-full" />
                            <span>Fly One</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/A3.avif" alt="Aegean Logo" className="w-6 h-6 rounded-full" />
                            <span>Aegean Air</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/AF.avif" alt="Air France Logo" className="w-6 h-6 rounded-full" />
                            <span>Air France</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/AZ.avif" alt="ITA Logo" className="w-6 h-6 rounded-full" />
                            <span>ITA Airways</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/H4.avif" alt="Hi Sky Logo" className="w-6 h-6 rounded-full" />
                            <span>Hi Sky</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <img src="https://static.ttm.network/images/airlines/64x64/W4.avif" alt="Wizz Air Logo" className="w-6 h-6 rounded-full" />
                            <span>Wizz Air</span>
                        </div>
                        <input type="checkbox" className="mr-2" />
                    </div>
                    {/* More airline entries */}
                    {showAllAirlines && (
                        <>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/U2.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>EasyJet</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/BA.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>British Airways</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/BT.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Air Baltic</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/D8.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Norvegian</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/EN.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Air Dolomiti SPA</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/EW.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Eurowings AG</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/FR.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Ryanair</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/EI.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Aer Lingus P.L.C</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/EK.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Emirates Airlines</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/LH.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Lufthansa</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/LO.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>LOT Polish Airlines</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/PC.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Pegasus Airlines</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/RO.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Tarom</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/TK.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Turkish Airlines</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <img src="https://static.ttm.network/images/airlines/64x64/VY.avif" alt="EasyJet Logo" className="w-6 h-6 rounded-full" />
                                    <span>Vueling</span>
                                </div>
                                <input type="checkbox" className="mr-2" />
                            </div>

                            {/* More airline entries */}
                        </>
                    )}
                    <button
                        className="text-blue-500 font-bold"
                        onClick={() => setShowAllAirlines(!showAllAirlines)}
                    >
                        {showAllAirlines ? "Arată mai puțin" : "Vezi mai mult"}
                    </button>
                </div>
            </div>

            {/* Filtru Aeroporturi */}
            <div className="space-y-2">
                <h4 className="block text-l font-bold text-neutral-700 dark:text-neutral-300">Aeroporturi</h4>
                <div className="space-y-2">
                    <label htmlFor="london2" className="flex items-center text-sm">
                        <input type="checkbox" id="london2" className="mr-2" />
                        <span>Aeroportul Gatwick</span>
                    </label>
                    <label htmlFor="london3" className="flex items-center text-sm">
                        <input type="checkbox" id="london3" className="mr-2" />
                        <span>Aeroportul Luton</span>
                    </label>
                    <label htmlFor="london4" className="flex items-center text-sm">
                        <input type="checkbox" id="london4" className="mr-2" />
                        <span>Aeroportul Stansted</span>
                    </label>
                    <label htmlFor="london5" className="flex items-center text-sm">
                        <input type="checkbox" id="london5" className="mr-2" />
                        <span>Aeroportul London City</span>
                    </label>
                    <label htmlFor="london6" className="flex items-center text-sm">
                        <input type="checkbox" id="london6" className="mr-2" />
                        <span>Aeroportul Southend</span>
                    </label>
                    {/* More airport checkboxes */}
                </div>
            </div>

            <TimeSelector
        minDuration={minDuration} // Add minDuration
        maxDuration={maxDuration} // Add maxDuration
        valuesDeparture={timeValuesDeparture}
        valuesLanding={timeValuesLanding}
        onChangeDeparture={handleTimeChangeDeparture}
        onChangeLanding={handleTimeChangeLanding}
        onReset={handleReset}
      />

        </div>
    );
};

export default FilterSidebar;
