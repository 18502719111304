// src/pages/SearchPage.tsx
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFilterCard from "./SectionGridFilterCard";
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import { searchFlights } from "../../services/search.service";
import { formatDate } from "../../utils/dateFormat";
import FilterSidebar from "./FilterSidebar";

export interface ListingFlightsPageProps {
  className?: string;
}

const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return {
    originLocation: queryParams.get("originLocation"),
    originCity: queryParams.get("originCity"),
    destinationLocation: queryParams.get("destinationLocation"),
    destinationCity: queryParams.get("destinationCity"),
    startDate: queryParams.get("startDate"),
    endDate: queryParams.get("endDate"),
  };
};

const SearchPage: FC<ListingFlightsPageProps> = ({ className = "" }) => {
  const { originLocation, originCity, destinationLocation, destinationCity, startDate, endDate } = useQueryParams();
  
  const [searchData, setSearchData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const search = () => {
    setLoading(true);
    const searchData = {
      origin: originLocation,
      destination: destinationLocation,
      departureDate: startDate ? formatDate(startDate) : "",
      returnDate: endDate ? formatDate(endDate) : "",
      adults: 1,
      children: 0,
      infants: 0,
    };

    searchFlights(searchData).then((res) => {
      setLoading(false);
      if (res.data) {
        setSearchData(res.data);
      }
    });
  };

  useEffect(() => {
    search();
  }, [originLocation, destinationLocation, startDate, endDate]);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />
      <SearchBlock isSearchPage={true} />
      <div className="container relative pt-14 mb-24 lg:mb-28">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Filter Sidebar */}
          <FilterSidebar />  {/* Folosește FilterSidebar */}
          
          {/* Flight Results */}
          <div className="lg:col-span-2">
            <SectionGridFilterCard
              loading={loading}
              searchData={searchData}
              originCity={originCity}
              destinationCity={destinationCity}
              className="pb-24 lg:pb-28"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
