import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next"; 
import SocialsList from "shared/SocialsList/SocialsList";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import '@fortawesome/fontawesome-free/css/all.min.css';
import SectionFAQ from "components/SectionFAQ/SectionFAQ";

const PageFAQ: FC = () => {
  const { t } = useTranslation(); 

  return (
    <div className="nc-PageContact overflow-hidden">
      <Helmet>
        <title>{t("pageFAQ.title")}</title>
      </Helmet>

      <div className=" max-w-7xl mx-auto py-20">
        <h2 className="text-center text-3xl md:text-5xl font-bold mb-10">
          {t("pageFAQ.header")}
        </h2>
        
          {/* FAQ */}
          <SectionFAQ/>

      </div>
    </div>
  );
};

export default PageFAQ;
