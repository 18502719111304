import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AirlineOffers: React.FC = () => {
    const { t } = useTranslation();
    const airlines = [
        { name: "FLYONE", image: "https://images.ttm.network/LP5BWtN7-mGbGFv7aP3F5SRZ1I8=/1200x630/filters:format(png)/d3a08b29061e47d8bd707f09b67530b5/bilete-flyone-pe-zbor-md-zboruri-directe-si-preturi-accesibile-1200-x-630.png" },
        { name: "HiSky", image: "https://cdn.prod.website-files.com/66e227a8e3a4dbbdaebff867/671216041789482f4653d62e_2e7d12b0-fe9c-4fb9-a40b-ce8536d3b02a.webp" },
        { name: "Wizz Air", image: "https://cdn.avia.md/?mode=scale&w=1150&c=1&url=https://manager.avia.md/images/ut_news_line/34eaf6befd754d5ba7455b7c4c04b474.jpg" },
        { name: "Turkish Airlines", image: "https://images.ttm.network/RtibcNaevh3iQTXzrIrl02xpkZo=/1200x630/filters:format(jpg)/d36995d1b8bc44e38616944e2dbe23a5/turkish-airlines-si-a-extins-reteaua-de-zboruri-catre-statele-unite-ale-americii-1200-x-630.jpg" },
        { name: "LOT Polish Airlines", image: "https://www.lot.com/content/dam/lot/lot-com/other-images/flota/embraer-175/LOT_Embraer175_fot_Jacek_Bonczek_bluesky_1.coreimg.jpg/1723631558829/LOT_Embraer175_fot_Jacek_Bonczek_bluesky_1.jpg" },
        { name: "Pegasus Airline", image: "https://upload.wikimedia.org/wikipedia/commons/c/cf/TC-CPF_%288749909789%29.jpg" },
        { name: "Bees Airline", image: "https://airservice.md/media/Bees1.jpg" },
    ];

    const [startIndex, setStartIndex] = useState(0);
    const [visibleItems, setVisibleItems] = useState(5); // Default pentru desktop
    const sliderRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(false);

    const updateResponsiveSettings = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setVisibleItems(1.5); // Mobile: 1 și jumătate din al doilea card
            setIsMobile(true);
        } else {
            setVisibleItems(5); // Desktop: 5 carduri vizibile
            setIsMobile(false);
        }
    };

    useEffect(() => {
        updateResponsiveSettings();
        window.addEventListener("resize", updateResponsiveSettings);
        return () => window.removeEventListener("resize", updateResponsiveSettings);
    }, []);

    const handleTouchStart = (e: React.TouchEvent) => {
        sliderRef.current!.dataset.touchStartX = e.touches[0].clientX.toString();
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        const touchStartX = parseFloat(sliderRef.current!.dataset.touchStartX || "0");
        const touchEndX = e.touches[0].clientX;
        const swipeDistance = touchStartX - touchEndX;

        if (swipeDistance > 50) {
            handleNext();
        } else if (swipeDistance < -50) {
            handlePrev();
        }
    };

    const handleNext = () => {
        const maxIndex = Math.max(0, airlines.length - Math.ceil(visibleItems));
        setStartIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
    };

    const handlePrev = () => {
        setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    return (
        <div className="py-10">
            <div className="container mx-auto px-0 text-left mb-2 sm:mb-4 mt-12">
                <h3 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-12">
                    {t("airlineoffers.title")}
                </h3>
                <div
                    ref={sliderRef}
                    className="relative flex items-center overflow-hidden"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    {/* Butoanele sunt vizibile doar pe desktop */}
                    {!isMobile && (
                        <>
                            <button
                                className="absolute left-0 z-10 w-10 h-10 bg-gray-300 rounded-md shadow-md hover:bg-gray-400 flex items-center justify-center"
                                onClick={handlePrev}
                                disabled={startIndex === 0}
                            >
                                &larr;
                            </button>
                            <button
                                className="absolute right-0 z-10 w-10 h-10 bg-gray-300 rounded-md shadow-md hover:bg-gray-400 flex items-center justify-center"
                                onClick={handleNext}
                                disabled={startIndex + visibleItems >= airlines.length}
                            >
                                &rarr;
                            </button>
                        </>
                    )}

                    {/* Lista cardurilor */}
                    <div className="relative w-full overflow-hidden">
                        <div
                            className="flex transition-transform duration-500 ease-in-out"
                            style={{
                                transform: `translateX(-${(startIndex * 100) / visibleItems}%)`,
                            }}
                        >
                            {airlines.map((airline, index) => (
                                <div
                                    key={index}
                                    className="flex-shrink-0 px-2"
                                    style={{
                                        width: `${100 / visibleItems}%`,
                                    }}
                                >
                                    <div className="h-36 rounded-xl bg-white border overflow-hidden">
                                        <img
                                            src={airline.image}
                                            alt={airline.name}
                                            className="w-full h-full object-cover rounded-xl"
                                        />
                                    </div>
                                    <div className="mt-2 px-2 mt-4">
                                        <p className="text-left text-xl font-medium">
                                            {airline.name}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Butonul principal */}
                <div className={`text-center ${isMobile ? "mt-6" : "sm:mt-12"}`}>
                    <Link to="/air-companies">
                        <button className="bg-[#0063a7] text-white font-bold px-16 py-3 text-lg rounded-lg hover:bg-[#004fce]">
                            {t("airlineoffers.button")}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AirlineOffers;
