import React from "react";
import { DEMO_POSTS } from "data/posts";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import { useTranslation } from "react-i18next";

// DEMO POST FOR MAGAZINE SECTION
export const MAGAZINE1_POSTS = DEMO_POSTS.filter((post, i: number) => i >= 0 && i < 8);

const SectionBlog: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="pt-12 pb-16 lg:pb-28">
      {/* Titlu */}
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold mt-12">
        {t("news.home_title")}
      </h2>

      {/* Descriere */}
      <p className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400 mb-12">
        {t("news.home_description")}
      </p>

      {/* === SECTION 1 === */}
      <SectionMagazine5 posts={MAGAZINE1_POSTS} />
    </div>
  );
};

export default SectionBlog;
