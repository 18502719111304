import React, { FC, useState } from "react";
import airCanada from 'images/logos/air-canada-logo.jpg';

const AllCountries: FC = () => {
    const [search, setSearch] = useState("");
    const alphabet = ["TOATE", "A", "B", "C", "D", "E", "F", "G", "I", "J", "M", "N", "P", "R", "S", "T", "V", "U", "Ț"];
    const countries = [
        { name: "Albania", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXGkoNaCMZPFj4slxYgP0kI9WQJzgf2nBR7Q&s" },
        { name: "Armenia", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE3eV8E4Cd5ykFCUvp-RBr9PHtN5rqqooIoQ&s" },
        { name: "Austria", offers: "Descoperiți ofertele", logo: "https://www.state.gov/wp-content/uploads/2018/11/Austria-2109x1406.jpg" },
        { name: "Belgia", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSwk76Yw9gzVU7yRcn8U1FNyv79T1oNsv3HQ&s" },
        { name: "Bulgaria", offers: "Descoperiți ofertele", logo: "https://photos.admiral.md/14cf8bb2cbf57ca58a18c4b696d28119.jpg" },
        { name: "Canada", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyL9IbOujvkGbLTF_mWvA9uIAxgn1alohWEw&s" },
        { name: "Cehia", offers: "Descoperiți ofertele", logo: "https://www.praga.md/images/chehia_3-1024x768.jpg" },
        { name: "China", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw3LvGShMeTVaHF04sdek9VZXG0C75PuOUuA&s" },
        { name: "Cipru", offers: "Descoperiți ofertele", logo: "https://pandatur.md/files/94b9bc3ab10b3b6599ca28356ed139e0.jpeg" },
        { name: "Coreea de Sud", offers: "Descoperiți ofertele", logo: "https://i0.wp.com/media.revistabiz.ro/uploads/2019/10/Coreea-de-Sud-Seul-Dreamstime-e1572593620525.jpg" },
        { name: "Croația", offers: "Descoperiți ofertele", logo: "https://aebc975c.rocketcdn.me/wp-content/uploads/2021/01/la-une-1-croatie.jpg" },
        { name: "Danemarca", offers: "Descoperiți ofertele", logo: "https://ik.imagekit.io/worldtravel/wp-content/uploads/croaziere-in-europa-de-nord-danemarca-copenhaga-thumb-900x900-il-aza-04.jpg" },
        { name: "Egipt", offers: "Descoperiți ofertele", logo: "https://travelplanner.ro/blog/wp-content/uploads/2023/01/egipt-850x550.jpg.webp" },
        { name: "Elveția", offers: "Descoperiți ofertele", logo: "https://calatorpovestitor.ro/wp-content/uploads/2022/06/20220626_143843.jpg" },
        { name: "Emiratele Arabe Unite", offers: "Descoperiți ofertele", logo: "https://planuridevacanta.ro/wp-content/uploads/2023/11/vacanta-abu-dhabi-5-10-februarie-2024-www-planuridevacanta-ro-896x708.jpg" },
        { name: "Filipine", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU4Je0j-Lhwe9kjSU0LRjlAFmh_4IX03YL5w&s" },
        { name: "Finlanda", offers: "Descoperiți ofertele", logo: "https://cdn.tripzaza.com/ro/destinations/wp-content/uploads/2017/04/Finland-1-Olavinlinna-e1492742240862.jpg" },
        { name: "Franța", offers: "Descoperiți ofertele", logo: "https://cdn.tripzaza.com/ro/destinations/wp-content/uploads/2017/04/France-1-The-Eiffel-Tower-e1492754610997.jpg" },
        { name: "Georgia", offers: "Descoperiți ofertele", logo: "https://cdn.bunniktours.com.au/public/posts/images/Europe/Tbilisi%20-%201234016323-feature.jpg" },
        { name: "Germania", offers: "Descoperiți ofertele", logo: "https://globalexpress.md/app/themes/global-express/assets/images/content/germania.webp" },
        { name: "Grecia", offers: "Descoperiți ofertele", logo: "https://noi.md/uploads/newsthumbs/760_500/2024_02_27/733362.webp" },
        { name: "Irlanda", offers: "Descoperiți ofertele", logo: "https://curiozitati.md/img/Irlanda/castel-in-Irlanda.jpg" },
        { name: "Israel", offers: "Descoperiți ofertele", logo: "https://t3.ftcdn.net/jpg/02/20/67/66/360_F_220676674_gYD7NlWnGqba4mK0CtNSagisu9vJCJGj.jpg" },
        { name: "Italia", offers: "Descoperiți ofertele", logo: "https://tvstar.md/wp-content/uploads/2024/10/italia-pais-1.jpg" },
        { name: "Japonia", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7DPj4xbmbrcxUb90sbMFIahVGVDvS-CSvAw&s" },
        { name: "Maldive", offers: "Descoperiți ofertele", logo: "https://pandatur.md/files/a53324f149eebffcee6abcac87c781c9.jpg" },
        { name: "Muntenegru", offers: "Descoperiți ofertele", logo: "https://pandatur.md/files/b2334b4e786fe4d861dcf87ec01a32c7.jpg" },
        { name: "Norvegia", offers: "Descoperiți ofertele", logo: "https://www.ghidultauonline.ro/content/images/size/w1600/2024/09/dreamstime_m_317505309.jpg" },
        { name: "Polonia", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUH0ahqn2PglWNnw7f0ve3hwEjkij5EBZCQA&s" },
        { name: "Portugalia", offers: "Descoperiți ofertele", logo: "https://ancapavel.ro/wp-content/uploads/2017/06/2885335422_f11568ae80_b-2-at-luxspehere-1024x655.jpg" },
        { name: "Regatul Unit", offers: "Descoperiți ofertele", logo: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/10/2f/fe/united-kingdom.jpg?w=1400&h=1400&s=1" },
        { name: "Republica Dominicană", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGe2nm-MBcYdsALfvfijDrNZe7oJE15qir5A&s" },
        { name: "România", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_U4yAQ10bsSrdWqwcV6GYZDAului-PZbsng&s" },
        { name: "Rusia", offers: "Descoperiți ofertele", logo: "https://i.natgeofe.com/k/415419c5-3768-4c8f-8697-f90de7a6075b/russia-st-basils.jpg?wp=1&w=1084.125&h=609" },
        { name: "Spania", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRolgLvW5uxp_TzeCTS1iW9Im1A6Luv29npCg&s" },
        { name: "Sri Lanka", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvppt9CHy5LDs6NiW9KOzVqIP_V5mk5radpQ&s" },
        { name: "Statele Unite ale Americii", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWO_0cZecD3iiRpxQCPRGM_KW0E6F3CT7lYj62valp1TNT7_Kh95HuuaPWaQkeLM_8jBY&usqp=CAU" },
        { name: "Suedia", offers: "Descoperiți ofertele", logo: "https://wise.com/imaginary-v2/3b1581010a72f40d2310888bab77a19f.jpg?width=1200" },
        { name: "Thailanda", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgb1HCPPLBrx24MFcjmuulu2pqrwXXwdAp5w&s" },
        { name: "Turcia", offers: "Descoperiți ofertele", logo: "https://familytur.md/wp-content/uploads/2023/05/corsica-brela-beach-hd-cool-wallpaper-in-high-resolution-wide-free-beaches-1882729485-1280x720.jpeg" },
        { name: "Ungaria", offers: "Descoperiți ofertele", logo: "https://pandatur.md/files/ce2e43ae2326325009270d2c27650e96.jpg" },
        { name: "Vietnam", offers: "Descoperiți ofertele", logo: "https://www.outlooktravelmag.com/media/vietnam-1-1611926800.profileImage.2x-jpg-webp.webp" },
        { name: "Țările de Jos", offers: "Descoperiți ofertele", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQNYdjzfVgJD46jmEaLi9W3NR4GNyVojwCmg&s" },
    ];

    const handleLetterClick = (letter: string) => {
        if (letter === "TOATE") {
            setSearch("");
        } else {
            setSearch(letter);
        }
    };

    const filteredCountry = countries.filter(country =>
        country.name.toLowerCase().startsWith(search.toLowerCase())
    );

    const handleCardClick = (companyName: string) => {
        window.location.assign("https://www.google.com");
    };

    return (
        <div className="bg-gray-100 min-h-screen py-10">
            <div className="container sm:max-w-[75%] mx-auto px-4 md:px-6 py-8">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                    Bilete de avion ieftine către diferite țări ale lumii
                </h1>
                <p className="text-lg sm:text-2xl font-medium text-gray-600 mb-6">
                    Alege destinația din lista de mai jos
                </p>
                <input
                    type="text"
                    placeholder="Începeți să introduceți un nume"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-full p-4 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
                <div className="flex flex-wrap gap-2 mb-6">
                    {alphabet.map((letter, index) => (
                        <button
                            key={index}
                            onClick={() => handleLetterClick(letter)}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none"
                        >
                            {letter}
                        </button>
                    ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredCountry.map((country, index) => (
                        <div
                            key={index}
                            className="flex items-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm transition-shadow cursor-pointer hover:shadow-md"
                            onClick={() => handleCardClick(country.name)}
                        >
                            <img
                                src={country.logo}
                                alt={country.name}
                                className="w-24 h-24 object-cover rounded-md"
                            />
                            <div className="ml-4">
                                <h3 className="text-xl font-medium text-gray-800">{country.name}</h3>
                                <p className="text-base text-gray-500">{country.offers}</p>
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default AllCountries;
