import React, { FC } from "react";

const PageBagInsurance: FC = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-10">
        <h3 className="text-left text-2xl font-bold mb-6">Condiții de Asigurare a Bagajelor</h3>
        <div className="text-left space-y-4">
          <p>Hi Fly oferă opțiuni flexibile de asigurare a bagajelor pentru a vă oferi liniștea de care aveți nevoie în timpul călătoriilor. Prin utilizarea serviciilor noastre, sunteți de acord cu termenii și condițiile descrise mai jos.</p>

          <h2 className="text-1xl font-semibold mt-6">1. Obiectul Asigurării</h2>
          <p>Asigurarea acoperă pierderea, deteriorarea sau întârzierea bagajelor în timpul călătoriilor, conform condițiilor stabilite de companiile aeriene și partenerii noștri.</p>

          <h2 className="text-1xl font-semibold mt-6">2. Procedura de Declarare</h2>
          <p>În cazul pierderii sau deteriorării bagajelor, pasagerii trebuie să notifice compania aeriană în termen de 24 de ore de la incident. Hi Fly oferă asistență pentru completarea cererilor de despăgubire.</p>

          <h2 className="text-1xl font-semibold mt-6">3. Limitările Răspunderii</h2>
          <p>Despăgubirile sunt limitate la valoarea declarată a bunurilor sau la suma maximă permisă de reglementările internaționale. Bunurile fragile sau de valoare ridicată trebuie declarate în prealabil.</p>

          <h2 className="text-1xl font-semibold mt-6">4. Excluderi</h2>
          <p>Nu sunt acoperite daunele cauzate de ambalarea necorespunzătoare, uzura normală sau confiscarea de către autorități. Vă rugăm să consultați termenii complet pentru detalii.</p>

          <h2 className="text-1xl font-semibold mt-6">5. Documentație Necesară</h2>
          <p>Pentru procesarea cererii, sunt necesare documente precum: biletul de călătorie, raportul de incident emis de compania aeriană și o listă detaliată a bunurilor pierdute sau deteriorate.</p>

          <h2 className="text-1xl font-semibold mt-6">6. Responsabilitatea Pasagerului</h2>
          <p>Pasagerii sunt responsabili pentru supravegherea bagajelor lor până la predarea acestora la compania aeriană și după recuperare. Asigurarea nu acoperă incidentele survenite în afara acestor intervale.</p>

          <h2 className="text-1xl font-semibold mt-6">7. Modificări ale Politicii</h2>
          <p>Hi Fly își rezervă dreptul de a modifica condițiile de asigurare a bagajelor în funcție de actualizările partenerilor și reglementările internaționale.</p>

          <h2 className="text-1xl font-semibold mt-6">8. Contact</h2>
          <p>Pentru mai multe informații sau asistență legată de asigurarea bagajelor, vă rugăm să ne contactați la support@hifly.md sau telefonic la +373 68 707 072.</p>
        </div>
      </div>
    </div>
  );
};

export default PageBagInsurance;