import React, {FC, useState} from "react";
import Lottie, {useLottie} from "lottie-react";
import Heading2 from "components/Heading/Heading2";
import FlightCard from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import FlightsLoader from "../../assets/animations/flights-loader.json";

export interface SectionGridFilterCardProps {
    className?: string;
    originCity: string | null;
    destinationCity: string | null;
    searchData?: any,
    loading: boolean
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                   className = "",
                                                                   originCity,
                                                                   destinationCity,
                                                                   searchData,
                                                                   loading
                                                               }) => {

    const options = {
        animationData: FlightsLoader,
        loop: true,

    };

    const {View} = useLottie(options);

    return (
        <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard"
        >
            <Heading2
                heading={`${originCity} - ${destinationCity}`}
                subHeading={
                    <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {searchData?.meta?.count ?? 0} flights
                        {/*<span className="mx-2">·</span>*/}
                        {/*round trip*/}
                        {/*<span className="mx-2">·</span>2 Guests*/}
          </span>
                }
            />

            {
                loading ? (
                    <div className={"flex justify-center"}>
                        <div className={"flex justify-center"} style={{width: 300, height: 300}}>
                            {View}
                        </div>
                    </div>
                ) : null
            }


            {
                !loading &&
                <div className="lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
                    {searchData?.data?.map((item: any, index: number) => (
                        <FlightCard defaultOpen={false} key={index} data={item}/>
                    ))}

                    {/*<div className="flex mt-12 justify-center items-center">*/}
                    {/*  <ButtonPrimary loading>Show more</ButtonPrimary>*/}
                    {/*</div>*/}
                </div>
            }

        </div>
    );
};

export default SectionGridFilterCard;
