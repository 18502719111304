"use client";

import React, { FC, useState } from "react";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface SearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Stays" | "Experiences" | "Cars" | "Flights";
}

const SearchForm: FC<SearchFormProps> = ({
  className = "",
  currentTab = "Stays",
  currentPage,
}) => {

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >

      <FlightSearchForm />
    </div>
  );
};

export default SearchForm;
