import { Tab } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import {
  Calendar,
  Clock,
  FileDown,
  XCircle,
  CheckCircle
} from 'lucide-react';

type Reservation = {
  id: string;
  from: string;
  to: string;
  departureDate: string;
  arrivalDate: string;
  flightNumber: string;
  status: 'upcoming' | 'completed' | 'cancelled';
  passengerName: string;
  seatNumber?: string;
};

const DEMO_RESERVATIONS: Reservation[] = [
  {
    id: "1",
    from: "New York (JFK)",
    to: "Londra (LHR)",
    departureDate: "2024-04-15T10:00:00",
    arrivalDate: "2024-04-15T22:30:00",
    flightNumber: "BA178",
    status: "upcoming",
    passengerName: "Ion Popescu",
    seatNumber: "12A"
  },
  // Adaugă mai multe rezervări demo...
];

const AccountSavelists = () => {
  let [categories] = useState(["Viitoare", "Finalizate", "Anulate"]);

  const renderReservationCard = (reservation: Reservation) => {
    return (
        <div key={reservation.id} className="border border-neutral-200 dark:border-neutral-700 rounded-xl p-6 relative">
          <div className={`absolute top-2 right-4 px-3 py-1 rounded-full text-sm
          ${reservation.status === 'upcoming' ? 'bg-green-100 text-green-800' :
              reservation.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                  'bg-gray-100 text-gray-800'}`}>
            {reservation.status === "upcoming" ? "Viitoare" : reservation.status === "cancelled" ? "Anulate" : "Finalizate"}
          </div>

          <div className="flex items-center justify-between pt-5 mb-4">
            <div className="text-2xl font-bold">{reservation.from}</div>
            <div className="border-t-2 flex-1 mx-4 border-neutral-300"></div>
            <div className="text-2xl font-bold">{reservation.to}</div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <div className="flex items-center text-neutral-500">
                <Calendar className="w-4 h-4 mr-2" />
                <span>Decolare</span>
              </div>
              <div className="font-medium">
                {new Date(reservation.departureDate).toLocaleString()}
              </div>
            </div>
            <div>
              <div className="flex items-center text-neutral-500">
                <Clock className="w-4 h-4 mr-2" />
                <span>Număr zbor</span>
              </div>
              <div className="font-medium">{reservation.flightNumber}</div>
            </div>
          </div>

          <div className="mb-4">
            <div className="text-neutral-500">Pasager</div>
            <div className="font-medium">{reservation.passengerName}</div>
            {reservation.seatNumber && (
                <div className="text-sm text-neutral-500">Loc: {reservation.seatNumber}</div>
            )}
          </div>

          <div className="flex gap-3 mt-4">
            {reservation.status === 'upcoming' && (
                <>
                  <ButtonSecondary className="flex-1">
                    Check-in
                  </ButtonSecondary>
                  <ButtonSecondary className="flex-1">
                    Anulează
                  </ButtonSecondary>
                </>
            )}
            <ButtonSecondary className="flex-1">
              <FileDown className="w-5 h-5 mr-2" />
              Bilet
            </ButtonSecondary>
          </div>
        </div>
    );
  };

  const renderSection1 = () => {
    return (
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h2 className="text-3xl font-semibold">Rezervările mele</h2>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div>
            <Tab.Group>
              <Tab.List className="flex space-x-1 overflow-x-auto">
                {categories.map((item) => (
                    <Tab key={item} as={Fragment}>
                      {({ selected }) => (
                          <button
                              className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                                  selected
                                      ? "bg-primary-6000 text-secondary-50 "
                                      : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                              } `}
                          >
                            {item}
                          </button>
                      )}
                    </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 lg:gap-8">
                    {DEMO_RESERVATIONS
                        .filter(r => r.status === 'upcoming')
                        .map(renderReservationCard)}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 lg:gap-8">
                    {DEMO_RESERVATIONS
                        .filter(r => r.status === 'completed')
                        .map(renderReservationCard)}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 lg:gap-8">
                    {DEMO_RESERVATIONS
                        .filter(r => r.status === 'cancelled')
                        .map(renderReservationCard)}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
    );
  };

  return (
      <div>
        <CommonLayout>{renderSection1()}</CommonLayout>
      </div>
  );
};

export default AccountSavelists;
