import React from "react";
import ReactDOM from "react-dom/client";
import { APIProvider } from "@vis.gl/react-google-maps";
import { HelmetProvider } from "react-helmet-async";
import { ClerkProvider } from '@clerk/clerk-react';
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import {Provider} from "react-redux";
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <HelmetProvider>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}>
          <ClerkProvider publishableKey={"pk_test_Ym9sZC1oZXJtaXQtMjAuY2xlcmsuYWNjb3VudHMuZGV2JA"}>
              <Provider store={store}>
              <App />
              </Provider>
          </ClerkProvider>,
      </APIProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
