import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Header3 from "components/Header/Header3";
import Header from "components/Header/Header";
import {
  ShoppingBagIcon as ShoppingCartIcon,
  Cog8ToothIcon as CogIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";

// export type SiteHeaders = "Header 1" | "Header 2" | "Header 3";
//
// interface HomePageItem {
//   name: string;
//   slug: PathName;
// }

// let OPTIONS = {
//   root: null,
//   rootMargin: "0px",
//   threshold: 1.0,
// };
// let OBSERVER: IntersectionObserver | null = null;
// const PAGES_HIDE_HEADER_BORDER: PathName[] = [
//   "/home-3",
//   "/listing-car-detail",
//   "/listing-experiences-detail",
//   "/listing-stay-detail",
// ];

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);


  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();

  // const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
  //   entries.forEach((entry) => {
  //     setIsTopOfPage(entry.isIntersecting);
  //   });
  // };
  //
  // useEffect(() => {
  //
  //
  //   // disconnect the observer
  //   if (!PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
  //     OBSERVER && OBSERVER.disconnect();
  //     OBSERVER = null;
  //     return;
  //   }
  //   if (!OBSERVER) {
  //     OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
  //     anchorRef.current && OBSERVER.observe(anchorRef.current);
  //   }
  // }, [location.pathname]);



  return (
    <>
      <Header className={"shadow-sm dark:border-b dark:border-neutral-700"} navType="MainNav1" />
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
