import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
                                                               className = "",
                                                             }) => {
  const { flyItem } = useSelector((state: any) => state.checkoutStoreModule);

  const [passengers, setPassengers] = useState([
    {
      firstName: "",
      lastName: "",
      birthDate: "",
      nationality: "",
      passportNumber: "",
      passportExpiry: "",
    },
  ]);

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedPassengers = [...passengers];
    (updatedPassengers[index] as any)[field] = value;
    setPassengers(updatedPassengers);
  };

  const renderSidebarDetail = () => {
    const segment = flyItem.itineraries[0].segments[0];
    const price = flyItem.price;

    return (
        <>
          {/* Detalii despre zbor */}
          <div className="listingSection__wrap lg:shadow-xl rounded-2xl p-6 bg-white">
          <span className="text-2xl font-semibold block mb-6">
            Detalii despre zbor
          </span>
            <div className="mt-4 flex">
              <div className="flex-shrink-0 flex flex-col items-center py-2">
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              </div>
              <div className="ml-4 space-y-8 text-sm">
                {/* Plecare */}
                <div className="flex flex-col space-y-1">
                <span className="text-neutral-500">
                  {new Date(segment.departure.at).toLocaleString("ro-RO", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                  <span className="font-semibold">
                  {segment.departure.iataCode}
                </span>
                </div>

                {/* Sosire */}
                <div className="flex flex-col space-y-1">
                <span className="text-neutral-500">
                  {new Date(segment.arrival.at).toLocaleString("ro-RO", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                  <span className="font-semibold">{segment.arrival.iataCode}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Preț */}
          <div className="mt-10 listingSectionSidebar__wrap shadow-xl rounded-2xl p-6 bg-white">
            <div className="flex justify-between mb-4">
            <span className="text-3xl font-semibold">
              {price.currency} {price.base}
              <span className="ml-1 text-base font-normal text-neutral-500">
                /bilet
              </span>
            </span>
              <StartRating />
            </div>

            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-600">
                <span>Preț de bază</span>
                <span>{price.currency} {price.base}</span>
              </div>
              <div className="flex justify-between text-neutral-600">
                <span>Taxe</span>
                <span>
                {price.currency}{" "}
                  {(parseFloat(price.grandTotal) - parseFloat(price.base)).toFixed(
                      2
                  )}
              </span>
              </div>
              <div className="border-b border-neutral-200"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>{price.currency} {price.grandTotal}</span>
              </div>
            </div>
            <div className="mt-6">
              <ButtonPrimary href={"/pay-done"}>Confirma</ButtonPrimary>
            </div>
          </div>
        </>
    );
  };

  const renderPassengerForm = () => {
    return passengers.map((passenger, index) => (
        <div key={index} className="border p-4 rounded-lg bg-white mb-4">
          <h3 className="font-semibold mb-4">Pasager {index + 1}</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Label>Nume</Label>
              <Input
                  value={passenger.lastName}
                  onChange={(e) =>
                      handleInputChange(index, "lastName", e.target.value)
                  }
              />
            </div>
            <div>
              <Label>Prenume</Label>
              <Input
                  value={passenger.firstName}
                  onChange={(e) =>
                      handleInputChange(index, "firstName", e.target.value)
                  }
              />
            </div>
            <div>
              <Label>Data Nașterii</Label>
              <Input
                  type="date"
                  value={passenger.birthDate}
                  onChange={(e) =>
                      handleInputChange(index, "birthDate", e.target.value)
                  }
              />
            </div>
            <div>
              <Label>Cetățenie</Label>
              <Input
                  value={passenger.nationality}
                  onChange={(e) =>
                      handleInputChange(index, "nationality", e.target.value)
                  }
              />
            </div>
          </div>
        </div>
    ));
  };

  return (
      <div className={`container mx-auto my-10 ${className}`}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-6">
            <h1 className="text-3xl font-bold mb-4">Checkout Bilete de Avion</h1>
            {renderPassengerForm()}
          </div>
          <div>{renderSidebarDetail()}</div>
        </div>
      </div>
  );
};

export default CheckOutPagePageMain;
